import React from "react";
import { Link, useHistory } from "react-router-dom";
import { P } from "notes";

export default () => {
  const history = useHistory();
  return (
    <P style={{ fontSize: "14px", marginBottom: "40px" }}>
      <Link onClick={() => history.goBack()}>Return to Previous Page</Link>
    </P>
  );
};
