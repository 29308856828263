import React, { Component } from "react";
import _ from "lodash";
import styled from "styled-components";
import { SurveyContext } from "Providers";
import { SongList } from "../../components/SongList";
import { injectIntl } from "react-intl";

class SongSelections extends Component {
  static contextType = SurveyContext;

  render() {
    const { songs } = this.context;

    return (
      <SelectionsContainer>
        <SongList songs={songs} />
      </SelectionsContainer>
    );
  }
}

export default injectIntl(SongSelections);

const SelectionsContainer = styled.div`
  text-align: center;
`;
