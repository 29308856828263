export default {
  'label.create_set_list': 'Crea tu lista de canciones',
  'prompt.which_concert': '¿A cuál de nuestros conciertos planeas asistir?',
  'label.upcoming_concerts': 'Próximos conciertos cerca:',
  'label.which_songs':
    '¿Cuál de nuestras canciones te gustaría que tocáramos en tu concierto?',
  'label.max_select': '(seleccione hasta {max})',
  'label.continue': 'Continuar',
  'label.selected_songs': 'Canciones seleccionadas',
  'message.no_songs_selected': 'Aún no has seleccionado ninguna canción ...',
  'button.submit': 'Enviar',
  'button.submitting': 'Enviando...',
  'button.previous': 'Previo',
  'button.next': 'Siguiente',
  'button.done': 'Terminar',
  'error.not.found':
    'Lo sentimos, pero no pudimos encontrar la encuesta solicitada. Verifique el enlace de la encuesta e intente nuevamente.',
  'label.not_attending': 'No voy a un concierto',
  'label.still_build':
    '(Pero todavía quiero crear una lista de reproducción personalizada)',
  'label.show_more_concerts': 'Mostrar conciertos adicionales',
  'label.song_search': 'Busca una canción por nombre o álbum...',
  'label.no_songs_found':
    'No se encontraron canciones que coincidan con "{search}". Revisa tu ortografía o prueba una nueva búsqueda ...',
  'label.featured_songs': 'Canciones destacadas',
  'label.popular_songs': 'Popular en Spotify',
  'label.learn_more': 'Aprende Más',
  'label.terms': 'Términos y Condiciones',
  'label.privacy': 'Política de Privacidad',
  'message.default_survey_intro':
    '¡Ya casi has terminado! Solo cuéntanos un poco sobre ti antes de enviar tu lista de reproducción...',
  'label.select': 'Por favor seleccione',
  'error.field_required': 'Este campo es requerido',
  'error.form':
    'Revise los errores resaltados antes de intentar su envío nuevamente.',
  'label.artist_terms': 'Términos y Condiciones de {artist}',
  'label.artist_privacy': 'Política de Privacidad de {artist}',
  'spotify.playlist':
    '¡No olvides convertir estas canciones en una lista de reproducción personalizada de Spotify!',
  'spotify.listen': 'Escuchar en Spotify',
  'spotify.share': 'Compartir mi lista de reproducción',
  'spotify.share.text':
    '¡Acabo de crear la lista de canciones perfecta para {artist} en Set The Set!',
  'label.max_songs_exceeded': 'No seleccione más de {max_songs} canciones',
  'message.no_songs_selected': 'Aún no has seleccionado ninguna canción ...',
  'label.song_count_multiple': '{selected_count} Canciónes seleccionadas',
  'label.song_count_single': '{selected_count} Canción seleccionada',
  'label.song_count_max_single': '{selected_count} de 1 Canción seleccionada',
  'label.song_count_max_multiple':
    '{selected_count} de {max_songs} Canciónes seleccionadas',
  'label.no_songs_selected': 'No hay canciones seleccionadas',
  'header.user_song_list': 'Lista de reproducción de {name}',
  'label.optional': 'opcional',
};
