import React, { useContext } from "react";
import _ from "lodash";
//import { Input, Dropdown, Icon, Label, Image, } from 'semantic-ui-react'
import styled from "styled-components";
import countries from "../../../modules/countries";
import InputMask from "react-input-mask";
import { Input, Select } from "notes";
import { CountrySelect } from "Components";
import { SurveyContext } from "Providers";

const Container = styled.div`
  display: flex;
`;

const PostalCode = ({ data = "", onChange, onBlur, error = false }) => {
  const {
    state: {
      current_responses: { country }
    },
    onResponse
  } = useContext(SurveyContext);

  const mask = !!country && country.id === "us" ? "99999" : null;

  const placeholder = !!country && country.id === "us" ? "00000" : "";

  const handleChange = value => {
    if (mask) {
      return onChange(value.substring(0, 5));
    }
    return onChange(value);
  };

  /*&&
    _.get(
      countries,
      `${country.value.toLowerCase()}.postalCode`,
      "9999 999999"
    );
*/

  const input = (value, placeholder, changeHandler, blurHandler) => {
    return (
      <StyledInput
        value={value}
        style={{
          borderRadius: "4px 0 0 4px"
        }}
        type="tel"
        placeholder={placeholder} //!!mask ? mask.replace(/9/g, "0").replace(/\*/g, "0") : ''}
        leftIcon={
          //TODO: move this to icon set
          <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 16C2.4 12.571 0 8.87 0 5.714 0 2.558 2.686 0 6 0s6 2.558 6 5.714C12 8.87 9.6 12.571 6 16zm5-10.286C11 3.122 8.772 1 6 1S1 3.122 1 5.714c0 2.5 1.802 5.662 5 8.886 3.198-3.224 5-6.387 5-8.886zM6 10a4 4 0 110-8 4 4 0 010 8zm0-1a3 3 0 100-6 3 3 0 000 6z"
              fill="#A6AEB2"
              fill-rule="evenodd"
            />
          </svg>
        }
        onChange={changeHandler}
        onBlur={blurHandler}
      />
    );
  };

  return (
    <Container>
      {!!mask ? (
        <InputMask
          maskChar=""
          mask={"99999"}
          value={data}
          onChange={handleChange}
          onBlur={onBlur}
          data-javelin-name={`input-postal-code`}
          data-javelin-meta={data}
        >
          {() => input(data, placeholder)}
        </InputMask>
      ) : (
        input(data, placeholder, onChange, onBlur)
      )}
      <CountrySelect
        selected={country}
        onChange={value => onResponse("country", value, "country")}
        data-javelin-name={`input-country`}
        data-javelin-meta={data && `${data}-${country}`}
      />
    </Container>
  );
};

export default PostalCode;

const StyledInput = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;
