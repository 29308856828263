import React, {useContext, Fragment} from 'react';
import _ from 'lodash';
//import { Header, Segment, Grid, } from 'semantic-ui-react'
import {SurveyContext} from 'Providers';
import Question from './Question';
import Intro from './Intro';

const Section = ({data = null}) => {
  const {
    state: {
      survey: {sections},
    },
    currentResponses,
    paymentHandler,
  } = useContext(SurveyContext);

  //const [ state, ] = useReducer(reducer, { section : data })

  /**
   * Display the questions for this section.  Questions can be organized
   * into 1 or more columns as specified in the section data.
   */
  const display = () => {
    //const section = _.get(state, 'section', null)

    const properties = _.get(data, 'properties', {});

    const grouped = _.get(properties, 'grouped', false);
    const cols = _.get(properties, 'columns', 1);
    const questions = _.get(data, 'Question', []).filter(
      ({status}) => parseInt(status, 10) === 1
    );

    if (!questions.length) return null;

    const questionsPerColumn =
      cols <= 1 ? questions.length : Math.ceil(questions.length / cols);

    let output = [];

    for (let i = 0; i < cols; i++) {
      let start = i * questionsPerColumn;
      let end = start + questionsPerColumn;
      if (end > questions.length) {
        end = questions.length;
      }
      let results = questions
        .slice(start, end)
        .map((question, index) =>
          question.id === 'zip' && !!paymentHandler.current ? null : (
            <Question key={`question_key_${index}`} data={question} />
          )
        );
      // was Grid.Column
      output.push(results);
    }

    return output;
  };

  const render = () => {
    if (!data) return null;

    const title = _.get(data, 'title', null);
    const intro = _.get(data, 'intro', null);

    return (
      <Fragment>
        {intro && <Intro text={intro} />}
        {title && <h3>{title}</h3>}
        {display()}
      </Fragment>
    );
  };

  return render();
};

export default Section;
