import React, { useContext } from "react";
import styled from "styled-components";
import { SurveyContext } from "Providers";
import { H4 } from "notes";
import { Header, ConnectedSongSelector } from "./";
import { FormattedMessage } from "react-intl";

export const MobileSongs = () => {
  const { getCustomization } = useContext(SurveyContext);

  let label = getCustomization("songSelectorLabel");

  return (
    <PageContainer>
      <Header simple />
      <SelectorContainer>
        <Subheader>
          {label ? (
            label
          ) : (
            <FormattedMessage
              id="label.which_songs"
              defaultMessage="Which of our songs would you like us to play at your concert?"
              values={{
                max: 7
              }}
            />
          )}
        </Subheader>
        <ConnectedSongSelector />
      </SelectorContainer>
    </PageContainer>
  );
};

const SelectorContainer = styled.div`
  padding-top: 24px;
`;

const PageContainer = styled.div``;

const Subheader = styled(H4)`
  color: ${props =>
    props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
  margin: 0 16px 24px 16px;
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => props.theme.lightDefault};
  }
`;
