import React from "react";
import { P } from "notes";
import styled from "styled-components";

const StyledIntro = styled(P)`
  margin-top: 20px;
`;

// Have to force margin reset inline to override semantic css
const Intro = ({ text = "" }) => <StyledIntro> {text} </StyledIntro>;

export default Intro;
