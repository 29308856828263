import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {
  FlexBox,
  P,
  LinkRight,
  CaretNextWhite,
  CaretNextDisabled,
  ButtonPrimary
} from "notes";
import { SurveyConsumer } from "Providers";
import { SongListHeader, SongListDisplay } from "./components";
import { withRouter } from "react-router-dom";
import { Link } from "Components";
import Api from "modules/Api";
import formatSongs from "modules/formatSongs";
import { FormattedMessage } from "react-intl";

const Container = styled(FlexBox).attrs(({ expanded }) => ({ expanded }))`
  .max-search-open & {
    display: none;
  }
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  flex-direction: column;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.32);
  z-index: 10;
  height: 100px;
  transition: height 250ms ease-in-out;
  ${props =>
    props.expanded &&
    `
  height: 80vh;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 0px 10px 4px rgba(0,0,0,0.8);
  `}
`;

const StatusContainer = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;
const ProgressContainer = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 32px 16px 16px 16px;
`;

const ProgressSection = styled.div.attrs(props => ({
  complete: props.complete
}))`
  height: 8px;
  border-radius: 8px;
  margin-right: 8px;
  flex-grow: 1;
  background-color: #e6e9eb;
  &:last-child: {
    margin-right: 0;
  }
  ${props => props.complete && "background-color: #6dcc6a;"}
`;

const Spacer = styled.div`
  height: 100px;
`;

const NextButton = styled(ButtonPrimary)`
  min-width: auto;
`;

const BackgroundShader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
`;

const BottomSpacer = () => {
  return ReactDOM.createPortal(
    <Spacer />,
    document.querySelector("#bottom-portal")
  );
};

const MobileProgressInternal = ({
  match: {
    params: { path }
  },
  location,
  history,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <SurveyConsumer>
      {({
        state: {
          collector,
          instance,
          collector: {
            setlist: { albums, concerts, max_songs }
          }
        },
        shows,
        songs,
        stage,
        setStage,
        saveResponses
      }) => (
        <>
          <Container expanded={expanded}>
            <StatusContainer>
              {(() => {
                switch (path) {
                  case "shows":
                    return (
                      <P>
                        <b>{shows.length}</b> Concert{shows.length !== 1 && "s"}{" "}
                        Selected
                      </P>
                    );
                  case "songs":
                    return (
                      <SongListHeader
                        expanded={expanded}
                        setExpanded={setExpanded}
                      />
                    );
                }
              })()}
              {(() => {
                let disabled = true;
                let linkTo = "";
                switch (path) {
                  case "shows":
                    disabled = shows.length < 1;
                    linkTo = "songs";
                    break;
                  case "songs":
                    disabled = songs.length < 1;
                    linkTo = "survey";
                    break;
                }
                if (path === "songs" && max_songs && songs.length > max_songs) {
                  return (
                    <NextButton
                      iconRight={
                        expanded ? <CaretNextDisabled /> : <CaretNextWhite />
                      }
                      onClick={() => setExpanded(true)}
                      disabled={expanded}
                    >
                      <FormattedMessage
                        id="button.next"
                        defaultMessage="Next"
                      />
                    </NextButton>
                  );
                }
                return disabled ? (
                  <NextButton iconRight={<CaretNextDisabled />} disabled>
                    <FormattedMessage id="button.next" defaultMessage="Next" />
                  </NextButton>
                ) : (
                  <Link to={linkTo}>
                    <NextButton
                      data-javelin-name={`next-button-${linkTo}`}
                      iconRight={<CaretNextWhite />}
                      onClick={() => {
                        switch (linkTo) {
                          case "songs":
                            // submit shows
                            saveResponses([
                              {
                                questionId: "shows",
                                value: shows
                              }
                            ]);
                            break;
                          case "survey":
                            // submit songs
                            saveResponses([
                              {
                                questionId: "songs",
                                value: formatSongs(songs, albums)
                              }
                            ]);
                            break;
                          default:
                            break;
                        }
                      }}
                    >
                      <FormattedMessage
                        id="button.next"
                        defaultMessage="Next"
                      />
                    </NextButton>
                  </Link>
                );
              })()}
            </StatusContainer>
            {expanded && max_songs && songs.length > max_songs && (
              <P style={{ padding: "0 16px 16px 16px", textAlign: "center" }}>
                <FormattedMessage
                  id="label.max_songs_exceeded"
                  defaultMessage="Please choose no more than {max_songs} songs"
                  values={{
                    max_songs
                  }}
                />
              </P>
            )}
            {path === "songs" && expanded && (
              <SongListDisplay expanded={expanded} setExpanded={setExpanded} />
            )}
            {!expanded && (
              <ProgressContainer>
                {concerts && <ProgressSection complete={shows.length} />}
                <ProgressSection
                  style={{ flexGrow: 2 }}
                  complete={songs.length}
                />
                <ProgressSection />
              </ProgressContainer>
            )}
            <BottomSpacer />
          </Container>
          {expanded && <BackgroundShader onClick={() => setExpanded(false)} />}
        </>
      )}
    </SurveyConsumer>
  );
};

export const MobileProgress = withRouter(MobileProgressInternal);
