import React, { useContext } from "react";
import { SurveyContext } from "Providers";
import styled from "styled-components";
import { P, Link, CaretNextPrimary, FlexBox } from "notes";
import { AlbumDisplay } from "./";
import { FormattedMessage } from "react-intl";

const TempColoredArrow = styled(CaretNextPrimary)`
  polygon {
    fill: ${props => props.theme.colors.border};
  }
  position: relative;
  top: -2px;
  right: -2px;
`;
const TextContainer = styled(FlexBox)`
  align-items: center;
  padding-left: 8px;
`;
const ArrowContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.32);
  transform: translate(-50%, -50%) rotate(-45deg);
  border-bottom-color: transparent;
  border-left-color: transparent;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SongListHeader = ({ expanded, setExpanded }) => {
  const {
    songs,
    state: {
      collector: {
        setlist: { albums, max_songs }
      }
    }
  } = useContext(SurveyContext);
  const selectedAlbums = albums.filter(album =>
    album.songs.find(albumSong => songs.includes(albumSong.id))
  );
  if (!songs.length && !expanded) {
    return (
      <P>
        <FormattedMessage
          id="label.no_songs_selected"
          default="No Songs Selected"
        />
      </P>
    );
  }
  return (
    <Link
      style={{ textDecoration: "none" }}
      onClick={() => setExpanded(!expanded)}
      data-javelin-name={
        expanded ? "song-list-mobile-close" : "song-list-mobile-open"
      }
    >
      <AlbumDisplay albums={selectedAlbums} />
      <TextContainer>
        {max_songs ? (
          max_songs > 1 ? (
            <FormattedMessage
              id="label.song_count_max_multiple"
              values={{
                selected_count: songs.length,
                max_songs: max_songs,
                multiple: (songs.length !== 1 || max_songs) && "s"
              }}
              defaultMessage={`${songs.length} of ${max_songs} Songs Selected`}
            />
          ) : (
            <FormattedMessage
              id="label.song_count_max_single"
              values={{
                selected_count: songs.length,
                max_songs: max_songs,
                multiple: (songs.length !== 1 || max_songs) && "s"
              }}
              defaultMessage={`${songs.length} of 1 Song Selected`}
            />
          )
        ) : songs.length > 1 ? (
          <FormattedMessage
            id="label.song_count_multiple"
            values={{
              selected_count: songs.length
            }}
            defaultMessage={`${songs.length} Songs Selected`}
          />
        ) : (
          <FormattedMessage
            id="label.song_count_single"
            values={{
              selected_count: songs.length
            }}
            defaultMessage={`${songs.length} Song Selected`}
          />
        )}
      </TextContainer>
      <ArrowContainer>
        <TempColoredArrow
          style={{ transform: expanded ? "rotate(135deg)" : "rotate(-45deg)" }}
        />
      </ArrowContainer>
    </Link>
  );
};
