import React from "react";
import styled from "styled-components";
import { Toolbox, P, Link, Small, FlexBox, IconInformation } from "notes";

const LinkContainer = styled(FlexBox)`
  justify-content: flex-end;
`;

const Icon = styled(IconInformation)`
  margin-right: 4px;
`;

const PopOver = ({ data = null }) => {
  return (
    <Toolbox
      placement="top"
      trigger={props => (
        <Small
          style={{
            cursor: "pointer",
            display: "inline-flex",
            justifyContent: "flex-start"
          }}
          {...props}
        >
          <Icon />
          {data.label}
        </Small>
      )}
    >
      {({ close }) => (
        <>
          <P>{data.content}</P>
          <LinkContainer>
            <Link onClick={close}>Got It</Link>
          </LinkContainer>
        </>
      )}
    </Toolbox>
  );
};

export default PopOver;
