import React from "react";
import { CountrySelector } from "notes";
import { CountryList } from "data";

export const CountrySelect = ({ selected, onChange, ...props }) => {
  if (!selected) {
    onChange(CountryList[0]);
  }
  return (
    <CountrySelector
      options={CountryList}
      onChange={onChange}
      selected={selected}
      country
      {...props}
    />
  );
};
