import React, {useState, useEffect, useContext} from 'react';
import {Input, P, FlexBox} from 'notes';
import styled from 'styled-components';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import {SurveyContext} from 'Providers';
import {useFirebaseContext} from 'hooks';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_PAYMENT_KEY ||
    'pk_test_iJQo5BRyTJHizvDDcMhjqDuv007acEsfxO'
);

const Wrapper = styled.div`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  @media only screen and ${props => props.theme.media.large} {
    max-width: 392px;
  }
`;

export const Payment = () => {
  const [val, setVal] = useState('');
  const [rawVal, setRawVal] = useState();
  const [paymentComplete, setPaymentComplete] = useState(false);
  const handleAmount = e => {
    if (e === '$' || e === '') {
      setRawVal();
      return setVal('');
    }
    const raw = e.replace(/\D/g, '');
    setRawVal(parseInt(raw));
    setVal('$' + raw);
  };

  return (
    <Wrapper>
      {paymentComplete ? (
        <P>Thanks for donating {val}!</P>
      ) : (
        <FlexBox style={{height: '40px'}}>
          <Input
            type="tel"
            value={val}
            onChange={handleAmount}
            style={{
              marginBottom: '14px',
              width: '100px',
              textAlign: 'right',
              flexGrow: 0,
            }}
            placeholder="Dollar Amount"
          />
          <Input
            disabled
            value=".00"
            style={{width: '60px', flexGrow: 0, height: '40px'}}
          />
        </FlexBox>
      )}
      <div
        style={{
          maxWidth: '450px',
          display: val && !paymentComplete ? 'block' : 'none',
        }}
      >
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm
            amount={rawVal}
            paymentComplete={paymentComplete}
            setPaymentComplete={setPaymentComplete}
          />
        </Elements>
      </div>
    </Wrapper>
  );
};

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#222',
      color: '#222',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#444',
      },
      '::placeholder': {
        color: '#444',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const CardField = ({onChange}) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const SubmitButton = ({processing, error, children, disabled}) => (
  <button
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </button>
);

const ErrorMessage = ({children}) => (
  <div className="ErrorMessage" role="alert" style={{color: '#8F150B'}}>
    {children}
  </div>
);

const ResetButton = ({onClick}) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

const CheckoutForm = ({amount, paymentComplete, setPaymentComplete}) => {
  const stripe = useStripe();
  const {callable} = useFirebaseContext();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentRequestLoading, setPaymentRequestLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [token, setToken] = useState();
  const {
    state: {instance},
    currentResponses,
    paymentHandler,
    setSkipZip,
  } = useContext(SurveyContext);

  useEffect(() => {
    if (!amount) {
      return;
    }
    if (stripe && elements) {
      if (paymentRequest) {
        paymentRequest.update({
          currency: 'usd',
          total: {
            label: 'Set The Set',
            amount: amount * 100,
          },
        });
        paymentRequest.on('paymentmethod', paymentRequestHandler);
        return () => {
          paymentRequest.off('paymentmethod', paymentRequestHandler);
        };
      } else {
        if (paymentRequestLoading) {
          return;
        }
        setPaymentRequestLoading(true);
        const paymentRequest = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Set The Set',
            amount: amount * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
        let cancelled = false;

        paymentRequest.canMakePayment().then(result => {
          if (result) {
            setPaymentRequest(paymentRequest);
            if (!cancelled) {
              paymentRequest.on('paymentmethod', paymentRequestHandler);
            }
          }
        });
        return () => {
          cancelled = true;
          if (paymentRequest.hasRegisteredListener('paymentmethod')) {
            paymentRequest.off('paymentmethod', paymentRequestHandler);
          }
        };
      }
    }
  }, [amount, paymentRequest]);

  const getSecret = async () => {
    const body = {
      amount: amount * 100,
      collectorId: instance.collectorId,
      instanceId: instance.id,
    };
    const {
      data: {client_secret},
    } = await callable('payments-create', body);
    return client_secret;
  };

  const paymentRequestHandler = async ev => {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const client_secret = await getSecret();

    const {error: confirmError} = await stripe.confirmCardPayment(
      client_secret,
      {payment_method: ev.paymentMethod.id},
      {handleActions: false}
    );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete('fail');
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete('success');
      // Let Stripe.js handle the rest of the payment flow.
      // const {
      //   error,
      //   paymentIntent
      // } = await stripe.confirmCardPayment(client_secret);
      // if (error) {
      //   // The payment failed -- ask your customer for a new payment method.
      // } else {
      setPaymentComplete(true);
      // }
    }
  };

  const getToken = async () => {
    const client_secret = await getSecret();

    const responses = currentResponses();

    const paymentResult = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
      receipt_email: responses.email,
    });

    setProcessing(false);

    if (paymentResult.error) {
      elements.getElement('card').focus();
      setError(paymentResult.error);
      return paymentResult;
    }
    setResult(paymentResult);
    setPaymentComplete(true);
    return paymentResult;
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (paymentComplete) {
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      setError(error);
      return {error};
    }

    if (cardComplete) {
      setProcessing(true);
      return getToken();
    } else {
      elements.getElement('card').focus();
      const error = {message: 'Complete Payment Details'};
      setError(error);
      return {error};
    }
  };

  useEffect(() => {
    if (amount) {
      paymentHandler.current = handleSubmit;
      setSkipZip(amount);
    }
    return () => {
      if (!paymentComplete) {
        paymentHandler.current = null;
        setSkipZip(false);
      }
    };
  }, [handleSubmit]);

  return (
    <form
      className="Form"
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <fieldset className="FormGroup" style={{margin: '20px 0 0 0'}}>
        <CardContainer>
          <CardField
            onChange={e => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </CardContainer>
      </fieldset>
      {error && (
        <P>
          <ErrorMessage>{error.message}</ErrorMessage>
        </P>
      )}
      {paymentRequest && (
        <>
          <OrContainer>
            <PaymentOr>Or</PaymentOr>
            <OrLine />
          </OrContainer>
          <PaymentRequestButtonElement
            key={amount}
            options={{paymentRequest}}
          />
        </>
      )}
    </form>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

const CardContainer = styled.div`
  border: 1px solid #222;
  border-radius: 4px;
  padding: 8px;
`;

const OrContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrLine = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => (props.theme.dark ? '#FFF' : '#222')};
  position: absolute;
  top: calc(50% - 1px);
`;
const PaymentOr = styled(P)`
  padding: 14px;
  background: ${props => (props.theme.dark ? '#000000' : '#FFF')};
  z-index: 1;
`;
