import React from "react";
import styled from "styled-components";
import { P } from "notes";

const RequiredField = ({ error, message, children, inline = false }) => {
  return (
    <div
      style={{
        marginBottom: error ? "31px" : "0",
        display: "inline-block",
        width: "100%"
      }}
      className={`required-field${error ? " error" : ""}`}
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, { required: true, error })
      )}
      {error && (
        <ErrorMessage>
          <ErrorIcon viewBox="0 0 16 16">
            <path
              d="M9.26.47A1 1 0 008.81 0a1 1 0 00-1.36.47l-7 14a1 1 0 00-.1.45 1 1 0 001 1h14a1.07 1.07 0 00.46-.11 1 1 0 00.45-1.36z"
              transform="translate(-.36 .09)"
              fill="#e45c52"
            />
            <path fill="#FFF" d="M7 5h2v5H7z" />
            <circle fill="#FFF" cx="8" cy="13" r="1" />
          </ErrorIcon>
          {message}
        </ErrorMessage>
      )}
    </div>
  );
};
/*
RequiredField.propTypes = {
    error: PropTypes.bool,
    message: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}
*/

RequiredField.defaultProps = {
  error: false,
  message: "This is a required field and cannot be blank."
};

export default RequiredField;

const ErrorIcon = styled.svg`
  height: 16px;
  margin-right: 12px;
  width: 16px;
`;

const ErrorMessage = styled(P)`
  align-items: center;
  color: #e45c52;
  display: flex;
  font-size: 15px;
  margin-top: 9px;
  position: absolute;
`;
