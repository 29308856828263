export const formatDate = date => {
  const arr = date.split(/[- :]/);
  const displayDate = new Date(
    arr[0],
    arr[1] - 1,
    arr[2],
    arr[3],
    arr[4],
    arr[5]
  );
  const displayDateOptions = {
    month: "short",
    day: "numeric",
    year: "numeric"
  };
  return displayDate.toLocaleDateString("en-us", displayDateOptions);
};
