import Joi from "@hapi/joi";
import _ from "lodash";
import countries from "./countries";

/**
 * Creates a single field validator based on the data-definition
 * using Joi.
 * @param {*} data
 */
export const createFieldValidation = data => {
  const validations = _.get(data, "validations", null);
  // For blur validations skip if value is blank or null
  if (!validations) return null;

  let schema = Joi.object({});

  const fieldType = _.get(validations, "type", "any");

  // v0.99 ;)
  switch (fieldType) {
    case "number":
      const min = _.get(validations, "min", null);
      const max = _.get(validations, "max", null);
      const integer = _.get(validations, "integer", null);
      schema = Joi.number();
      if (integer) {
        schema = schema.integer();
      }
      if (min) {
        schema = schema.min(min);
      }
      if (max) {
        schema = schema.max(max);
      }
      break;
    default:
      schema = Joi.any();
      break;
  }

  const required = _.get(validations, "required", false);

  if (required) {
    schema = schema.required();
  }

  return schema;
};

/**
 * Validates a single field based on data definition
 * @param {*} data
 * @param {*} value
 */
export const validateField = (data, value) => {
  try {
    const schema = createFieldValidation(data);
    if (!!schema) {
      // the result will have an { error : message } field if invalid
      return schema.validate(value);
    }
  } catch (err) {
    console.error(err);
    return { error: err.message };
  }

  // default to valid (no error field) that can still
  // be destructured.
  return {};
};

/**
 * There are extra validators that are more type-specific that can
 * be attached to a data object.
 * @param {*} validators
 * @param {*} value
 * @param {*} locale
 */
export const checkValidators = (
  validators = [],
  value = "",
  locale,
  allValues
) => {
  let valid = true;
  validators.forEach(validator => {
    if (valid) {
      switch (validator) {
        case "phone":
          const mask = _.get(
            countries,
            `${locale}.phone`,
            "9999999999"
          ).replace(/\D/g, "");
          const val =
            !!value && value !== "" && typeof value === "string"
              ? value.replace(/\D/g, "")
              : "";
          valid =
            locale === "us"
              ? val.length === 0 || val.length === mask.length
              : true;
          break;
        case "zip":
          const {
            country: { value: countryValue }
          } = allValues;
          valid =
            countryValue === "us"
              ? /^\d{5}$|^\d{5}-\d{4}$/.test(value)
              : !!value;
          break;
        case "age":
          valid = parseInt(value) >= 13;
          break;
        case "year":
          valid =
            /^\d{4}$/.test(value) &&
            value > 1900 &&
            value <= new Date().getFullYear() - 13;
          break;
        case "email":
          valid = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
          break;
        default:
          valid = value !== "";
          break;
      }
    }
  });
  return valid;
};
