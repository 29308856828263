import React, { useState } from "react";
import _ from "lodash";
//import { Input, Dropdown, Icon, Label, Image, } from 'semantic-ui-react'
import styled from "styled-components";
import countries from "../../../modules/countries";
import InputMask from "react-input-mask";
import { Select, Input, FlexBox } from "notes";
import { CountrySelect } from "Components";
import { CountryList } from "data";

const Container = styled(FlexBox)`
  position: relative;
`;

const CountryPhone = ({ phoneNumber: value, onChange }) => {
  const [country, setCountry] = useState(CountryList[0]);
  // if receiving data formatted with a country code
  // remove it and reformat
  if (value && typeof value !== "object") {
    if (value.indexOf("+") > -1) {
      let parts = value.replace(/\+/g, "").split(" ");
      if (parts.length > 1) {
        // remove country code
        parts.shift();
        value = parts.join();
      }
    }
    // make sure there are no spaces
    value = value.replace(/\s/g, "");
  }

  if (!country.value) {
    return null;
  }

  const countryKey = country.value.toLowerCase();
  const countryCode = _.get(countries, `${countryKey}.countryCode`, "");
  let phoneMask = "999999999999999";
  if (countryKey === "us") {
    phoneMask = _.get(countries, `${countryKey}.phone`, "9999 999999");
  }
  const mask = `${countryCode !== "" ? `+${countryCode} ` : ""}${phoneMask}`;

  return (
    <Container>
      <InputMask maskChar="" mask={mask} value={value} onChange={onChange}>
        {() => (
          <StyledInput
            style={{
              borderRadius: "4px 0 0 4px"
            }}
            type="tel"
            data-javelin-name={`input-phone`}
            data-javelin-meta={JSON.stringify(value)}
            leftIcon={
              //TODO: move to iconset
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 16"
                width="16"
                height="16"
              >
                <defs />
                <path
                  fill="#A6AEB2"
                  fill-rule="evenodd"
                  d="M9 0c.5522847 0 1 .44771525 1 1v14c0 .5522847-.4477153 1-1 1H1c-.55228475 0-1-.4477153-1-1V1c0-.55228475.44771525-1 1-1h8zM5 13c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1 1-.4477153 1-1-.44771525-1-1-1zM9 1H1v11h8V1z"
                />
              </svg>
            }
            placeholder={!!mask ? mask.replace(/9/g, "0") : ""}
          ></StyledInput>
        )}
      </InputMask>
      <CountrySelect selected={country} onChange={setCountry} />
    </Container>
  );
};

export default CountryPhone;

const StyledInput = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;
