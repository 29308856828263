import React, {useContext, useMemo} from 'react';
import {SurveyContext, SurveyProvider} from 'Providers';
import {P, SingleSong} from 'notes';
import styled from 'styled-components';

const SongContainer = styled.div`
  overflow: scroll;
`;
export const SongListDisplay = () => {
  const {
    songs,
    setSongs,
    state: {
      collector: {
        setlist: {albums},
      },
    },
  } = useContext(SurveyContext);
  //TODO: relocate this zipping of data to a provider as it has gotten used a couple times now
  const songsData = useMemo(
    () =>
      albums.reduce((accum, album) => {
        const albumSongs = album.songs.map(song => ({
          ...song,
          album,
        }));
        return accum.concat(albumSongs);
      }, []),
    [albums]
  );
  const selectedSongs = useMemo(
    () =>
      songsData
        .filter(({id}) => songs.includes(id))
        .sort(
          ({id: idA}, {id: idB}) => songs.indexOf(idA) - songs.indexOf(idB)
        ),
    []
  ); //don't update the displayed songs after mounting
  return (
    <SongContainer>
      {selectedSongs.map(song => (
        <SingleSong {...song} value={songs} onChange={setSongs} />
      ))}
    </SongContainer>
  );
};
