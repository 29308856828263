import React from "react";
import styled, { keyframes, withTheme } from "styled-components";

const load1 = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
`;

const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
`;

const LoaderInternal = styled.div`
  background: ${props => (props.color ? props.color : "#39a4a1")};
  animation: ${load1} 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  color: ${props => (props.color ? props.color : "#39a4a1")};
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &:before,
  &:after {
    background: ${props => (props.color ? props.color : "#39a4a1")};
    animation: ${load1} 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    position: absolute;
    top: 0;
    content: "";
  }

  &:after {
    left: 1.5em;
  }

  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }
`;

export default withTheme(({ matches, theme }) => (
  <LoaderContainer>
    <LoaderInternal color={theme.colors.action} />
  </LoaderContainer>
));
