import { useReducer, useCallback, useRef, useEffect } from 'react';

/**
 * Reducer for hook state and actions
 * @type {React.Reducer<Object,Object>}
 * 
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state, action) => {
    switch (action.type) {
      case "idle":
        return { status: "idle", data: undefined, error: undefined };
      case "loading":
        return { status: "loading", data: undefined, error: undefined };
      case "success":
        return { status: "success", data: action.payload, error: undefined };
      case "error":
        return { status: "error", data: undefined, error: action.payload };
      default:
        throw new Error("invalid action");
    }
  }

function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}

/**
 * 
 * @typedef {Object} FirestoreQuery
 * @property {'loading'|'idle'} status
 * @property {Object[]} data
 * @property {any} error
 * 
 * @param {firebase.firestore.Query} query 
 * @returns {FirestoreQuery}
 */
export const useFirestoreQuery = (query, handler = async docs => docs) => {
    // Our initial state
    // Start with an "idle" status if query is falsy, as that means hook consumer is
    // waiting on required data before creating the query object.
    // Example: useFirestoreQuery(uid && firestore.collection("profiles").doc(uid))
    const initialState = { 
      status: query ? "loading" : "idle", 
      data: undefined, 
      error: undefined 
    };
  
    const callback = useCallback(handler, []);
  
    /**
     * Setup our state and actions
     * @type [React.ReducerState<reducer>, React.Dispatch<React.ReducerAction<reducer>>]
     */
    const [{
      status,
      data,
      error
    }, dispatch] = useReducer(reducer, initialState);
    
    // Get cached Firestore query object with useMemoCompare (https://usehooks.com/useMemoCompare)
    // Needed because firestore.collection("profiles").doc(uid) will always being a new object reference
    // causing effect to run -> state change -> rerender -> effect runs -> etc ...
    // This is nicer than requiring hook consumer to always memoize query with useMemo.
    const queryCached = useMemoCompare(query, prevQuery => {
      // Use built-in Firestore isEqual method to determine if "equal"
      return prevQuery && query && query.isEqual(prevQuery);
    });
  
    useEffect(() => {
      // Return early if query is falsy and reset to "idle" status in case
      // we're coming from "success" or "error" status due to query change.
      if (!queryCached) {
        dispatch({ type: "idle" });
        return;
      }
      
      dispatch({ type: "loading" });
      
      // Subscribe to query with onSnapshot
      // Will unsubscribe on cleanup since this returns an unsubscribe function
      return queryCached.onSnapshot(
        response => {
          callback(response.docs).then((res) => {
            dispatch({ type: "success", payload: res });
          });
        },
        error => {
          dispatch({ type: "error", payload: error });
        }
      );
      
    }, [queryCached, callback]); // Only run effect if queryCached changes
  
    return [status, data, error];
    
  }