// @flow

/**
 *
 * TermsOfService
 *
 */
import React from "react";
import { Link } from "react-router-dom";
import {
  ReturnLink,
  Paragraph,
  ParagraphTitle,
  PageHeader,
  LegalContainer
} from "./components";

export default () => {
  return (
    <LegalContainer>
      <ReturnLink />

      <Paragraph>
        PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW)
        ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE
        FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
      </Paragraph>

      <PageHeader>Set The Set Terms of Use</PageHeader>
      <Paragraph>Effective date: February 4, 2020</Paragraph>

      <Paragraph>
        Welcome to www.settheset.com. Please read on to learn the rules and
        restrictions that govern your use of our website(s), products, services,
        applications, and any other site that posts a link to this page (the
        &ldquo;Services&rdquo;). If you have any questions, comments, or
        concerns regarding these terms or the Services, please contact us at
        privacy@settheset.com.
      </Paragraph>
      <Paragraph>
        These Terms of Use (the &ldquo;Terms&rdquo;) are a binding contract
        between you and Set the Set, Inc. (&ldquo;Set the Set,&rdquo;
        &ldquo;Company,&rdquo; &ldquo;we&rdquo; and &ldquo;us&rdquo;). You must
        agree to and accept all of the Terms, or you don&rsquo;t have the right
        to use the Services. Your using the Services in any way means that you
        agree to all of these Terms, and these Terms will remain in effect while
        you use the Services. These Terms include the provisions in this
        document, as well as those in the .
      </Paragraph>
      <Paragraph>
        PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICES. &lrm;THEY
        APPLY TO ALL &lrm;USERS OF THE SERVICES, REGARDLESS OF WHETHER THEY SIGN
        UP FOR AN ACCOUNT.{" "}
      </Paragraph>
      <ParagraphTitle>Ability to accept Terms</ParagraphTitle>
      <Paragraph>
        You affirm that you are the age of majority in your jurisdiction and are
        fully able and competent to &lrm;enter into the terms, conditions,
        obligations, affirmations, representations, and warranties set forth
        &lrm;in these Terms, and to abide by and comply with these Terms. The
        Services is not intended for children &lrm;under the age of eighteen
        (18). If you are under 18 years of age, then please exit now and do not
        &lrm;use the Services.&lrm;
      </Paragraph>
      <ParagraphTitle>Will these Terms ever change?</ParagraphTitle>
      <Paragraph>
        We are constantly trying to improve our Services, so these Terms may
        need to change along with the Services. We reserve the right to change
        the Terms at any time, but if we do, we will bring it to your attention
        by placing a notice on the www.settheset.com website, by sending you an
        email, and/or by some other means. Changes to the Terms become effective
        immediately.{" "}
      </Paragraph>
      <Paragraph>
        If you don&rsquo;t agree with the new Terms, you are free to reject
        them; unfortunately, that means you will no longer be able to use the
        Services. If you use the Services in any way after a change to the Terms
        is effective, that means you agree to all of the changes.
      </Paragraph>
      <ParagraphTitle>What about my privacy?</ParagraphTitle>
      <Paragraph>
        Set the Set takes the privacy of its users seriously. For the current
        Set the Set Privacy Policy, please click <Link to="/privacy">here</Link>
        .
      </Paragraph>
      <ParagraphTitle>What are the basics of using Set the Set?</ParagraphTitle>
      <Paragraph>
        You may be required to sign up for an account, and select a password and
        user name (&ldquo;User ID&rdquo;). You promise to provide us with
        accurate, complete, and updated registration information about yourself.
        You may not select as your User ID a name that you don&rsquo;t have the
        right to use, or another person&rsquo;s name with the intent to
        impersonate that person. You may not transfer your account to anyone
        else without our prior written permission. You may change or edit your
        information by logging into your account.
      </Paragraph>
      <Paragraph>
        You will only use the Services in a manner that complies with all laws
        that apply to you. If your use of the Services is prohibited by
        applicable laws, then you aren&rsquo;t authorized to use the Services.
        We can&rsquo;t and won&rsquo;t be responsible for your using the
        Services in a way that breaks the law.
      </Paragraph>
      <Paragraph>
        You will not share your account or password with anyone, and you must
        protect the security of your account and your password. You&rsquo;re
        responsible for any activity associated with your account.
      </Paragraph>
      <Paragraph>
        Without our prior consent, you agree that you will not use the Services
        (i) in &lrm;connection with any commercial activities and/or sales,
        including without limitation advertising, contests, &lrm;sweepstakes or
        pyramid schemes, without our prior consent, (ii) to promote information
        that you &lrm;know is false or misleading or to promote illegal
        activities or conduct that is abusive, threatening, obscene,
        &lrm;defamatory or libelous, (iii) to further or promote any criminal
        activity or enterprise, including without &lrm;limitation, harassment,
        stalking, copyright infringement, patent infringement, or theft of trade
        secrets, (iv) &lrm;to solicit personally identifiable information for
        unlawful purposes, (v) to advertise to, &lrm;solicit, or sell to any
        person without their prior explicit consent, (vi) to harvest or collect
        personally &lrm;identifiable information such as e-mail addresses,
        account names, passwords or other contact information &lrm;of users or
        members for purposes of sending unsolicited communications or commercial
        solicitations without prior consent. &nbsp;In &lrm;order to protect
        third parties and other users from such prohibited conduct, we reserve
        the right to restrict, in &lrm;our sole discretion, communications which
        a user may send through the Services. &nbsp;&lrm;
      </Paragraph>
      <Paragraph>
        Your use of the Services is subject to the following additional
        restrictions:
      </Paragraph>
      <Paragraph>
        You represent, warrant, and agree that you will not contribute any
        Content or User Submission (each of those terms is defined below) or
        otherwise use the Services or interact with the Services in a manner
        that:
      </Paragraph>
      <Paragraph>
        Infringes or violates the intellectual property rights or any other
        rights of anyone else (including Set the Set);
      </Paragraph>
      <Paragraph>
        Accesses, solicits, collects, or uses any personally identifying
        information about any other users of the Services or anyone else;
      </Paragraph>
      <Paragraph>
        Violates any law or regulation, including any applicable export control
        laws;
      </Paragraph>
      <Paragraph>
        Is harmful, fraudulent, deceptive, threatening, harassing, defamatory,
        obscene, or otherwise objectionable;
      </Paragraph>
      <Paragraph>
        Jeopardizes the security of your Set the Set account or anyone
        else&rsquo;s (such as allowing someone else to log in to the Services as
        you);
      </Paragraph>
      <Paragraph>
        Attempts, in any manner, to obtain the password, account, or other
        security information from any other user;
      </Paragraph>
      <Paragraph>
        Violates the security of any computer network, or cracks any passwords
        or security encryption codes;
      </Paragraph>
      <Paragraph>
        Runs Maillist, Listserv, any form of auto-responder or
        &ldquo;spam&rdquo; on the Services, or any processes that run or are
        activated while you are not logged into the Services, or that otherwise
        interfere with the proper working of the Services (including by placing
        an unreasonable load on the Services&rsquo; infrastructure);
      </Paragraph>
      <Paragraph>
        &ldquo;Crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo;
        any page, data, or portion of or relating to the Services or Content
        (through use of manual or automated means);
      </Paragraph>
      <Paragraph>
        Copies or stores any significant portion of the Content;
      </Paragraph>
      <Paragraph>
        Decompiles, reverse engineers, or otherwise attempts to obtain the
        source code or underlying ideas or information of or relating to the
        Services;
      </Paragraph>
      <Paragraph>
        Restricts or inhibits others from using the Services, or interferes with
        the proper operation of the Services;
      </Paragraph>
      <Paragraph></Paragraph>
      <Paragraph>
        Set the Set has no obligation to monitor your interaction with the
        Services, but reserves the right to review or monitor the Services and
        any User Submission in its sole discretion. We reserve the right, in our
        sole discretion, to revoke, terminate or suspend any and all privileges
        &lrm;associated with accessing the Services for any reason or for no
        reason whatsoever including improper &lrm;use of the Services or failure
        to comply with these Terms, and to take any other action we deem
        &lrm;appropriate.&lrm;{" "}
      </Paragraph>
      <ParagraphTitle>What are my rights in the Services?</ParagraphTitle>
      <Paragraph>
        The materials displayed or performed or available on or through the
        Services, including, but not limited to, text, graphics, data, articles,
        photos, images, illustrations, User Submissions, and so forth (all of
        the foregoing, the &ldquo;Content&rdquo;) are protected by copyright
        and/or other intellectual property laws. You promise to abide by all
        copyright notices, trademark rules, information, and restrictions
        contained in any Content you access through the Services, and you
        won&rsquo;t use, copy, reproduce, modify, translate, publish, broadcast,
        transmit, distribute, perform, upload, display, license, sell or
        otherwise exploit for any purpose any Content not owned by you, (i)
        without the prior consent of the owner of that Content or (ii) in a way
        that violates someone else&rsquo;s (including Set the Set&rsquo;s)
        rights.
      </Paragraph>
      <Paragraph>
        You understand that Set the Set owns the Services. You won&rsquo;t
        modify, publish, transmit, participate in the transfer or sale of,
        reproduce (except as expressly provided in this Section), create
        derivative works based on, or otherwise exploit any of the Services.
      </Paragraph>
      <Paragraph>
        The Services may allow you to copy or download certain Content; please
        remember that just because this functionality exists, doesn&rsquo;t mean
        that all the restrictions above don&rsquo;t apply &ndash; they do!
      </Paragraph>
      <ParagraphTitle>
        Do I have to grant any licenses to Company or to other users?
      </ParagraphTitle>
      <Paragraph>
        Anything you post, upload, share, store, or otherwise provide through
        the Services, or provide to us in order to utilize the Services, is your
        &ldquo;User Submission.&rdquo; Some User Submissions are viewable by
        other users, and you understand that we do not guarantee any
        confidentiality with respect to any User Submissions. In order to
        display your User Submissions on the Services, and to allow other users
        to enjoy them (where applicable), you grant us certain rights in those
        User Submissions. Please note that all of the following licenses are
        subject to our &nbsp;to the extent they relate to User Submissions that
        are also your personally-identifiable information.
      </Paragraph>
      <Paragraph>
        You shall be solely responsible for your own User Submissions and the
        consequences of submitting, posting &lrm;or publishing them. &nbsp;In
        connection with User Submissions, you affirm, represent, and warrant
        that: (i) you &lrm;own or have the necessary licenses, rights, consents,
        and permissions to use and authorize us to use all &lrm;copyrights,
        trademarks, trade secrets, patents or any other proprietary or personal
        rights in and to your User &lrm;Submissions to enable inclusion and use
        of such User Submissions in the manner contemplated by the Services
        &lrm;and these Terms; (ii) submitting, posting or publishing your User
        Submission on or through the Services does not &lrm;and will not violate
        any confidentiality obligations between you and any person or
        organization or the &lrm;privacy rights, publicity rights or other
        rights of any person; and (iii) you have the written consent, release,
        &lrm;and/or permission of each and every identifiable person or
        organization in your User Submission to use the &lrm;name or likeness of
        each and every such identifiable person or organization to enable
        inclusion and use of &lrm;the User Submissions in the manner
        contemplated by the Services and these Terms. &nbsp;You further affirm,
        &lrm;represent and warrant that your User Submissions are not defamatory
        or libelous in any manner &lrm;whatsoever.&lrm;
      </Paragraph>
      <Paragraph>
        You understand that when using the Services, you will be exposed to User
        Submissions and third party &lrm;information from a variety of sources
        and that we are not responsible for the accuracy, usefulness, safety
        &lrm;or intellectual property rights of or relating to such User
        Submissions and third-party information. &nbsp;You &lrm;further
        understand and acknowledge that you may be exposed to User Submissions
        that are inaccurate, &lrm;offensive, indecent, or objectionable, and you
        agree to waive, and hereby do waive, any legal or equitable &lrm;rights
        or remedies you have or may have against us with respect thereto.&lrm;
      </Paragraph>
      <Paragraph>
        For all User Submissions, you hereby grant Company a license to
        translate, modify (for technical purposes, for example making sure your
        content is viewable on an iPhone as well as a computer) and reproduce
        and otherwise act with respect to such User Submissions, in each case to
        enable us to operate the Services, as described in more detail below.
        This is a license only &ndash; your ownership in User Submissions is not
        affected. You further hereby waive any and all moral rights and all
        rights of a similar nature in any jurisdiction in &lrm;your User
        Submission.&lrm;
      </Paragraph>
      <Paragraph>
        If you share a User Submission publicly on the Services and/or in a
        manner that more than just you or certain specified users can view, or
        if you provide us (in a direct email or otherwise) with any feedback,
        suggestions, improvements, enhancements, and/or feature requests
        relating to the Services (each of the foregoing, a &ldquo;Public User
        Submission&rdquo;), then you grant Company the licenses above, as well
        as a license to display, perform, and distribute your Public User
        Submission for the purpose of making that Public User Submission
        accessible to all Company users and providing the Services necessary to
        do so, as well as all other rights necessary to use and exercise all
        rights in that Public User Submission in connection with the Services
        and/or otherwise in connection with Company&rsquo;s business for any
        purpose. Also, you grant all other users of the Services a license to
        access that Public User Submission, and to use and exercise all rights
        in it, as permitted by the functionality of the Services.
      </Paragraph>
      <Paragraph>
        You agree that the licenses you grant are non-exclusive, transferable,
        fully-paid up, royalty-free, perpetual, sublicenseable, irrevocable, and
        worldwide, provided that when you delete your Company account, we will
        stop displaying your User Submissions (other than Public User
        Submissions, which may remain fully available) to other users (if
        applicable), but you understand and agree that it may not be possible to
        completely delete that content from Company&rsquo;s records, and that
        your User Submissions may remain viewable elsewhere to the extent that
        they were copied or stored by other users.
      </Paragraph>
      <Paragraph>
        You agree that any questions, comments, suggestions, ideas, feedback or
        other information provided by you to us are not confidential and you
        grant us a worldwide, royalty-free license to distribute, publish,
        modify, edit or otherwise use your submissions. We are entitled to the
        unrestricted use and dissemination of these submissions for any purpose,
        commercial or otherwise without any acknowledgement of or compensation
        to you.
      </Paragraph>
      <Paragraph>
        Finally, you understand and agree that Company, in performing the
        required technical steps to provide the Services to our users (including
        you), may need to make changes to your User Submissions to conform and
        adapt those User Submissions to the technical requirements of connection
        networks, devices, services, or media, and the foregoing licenses
        include the rights to do so.
      </Paragraph>
      <ParagraphTitle>
        What if I see something on the Services that infringes my copyright?
      </ParagraphTitle>
      <Paragraph>
        If you are a copyright owner or an agent thereof and believe that any
        User Submission or other Content infringes upon your copyright, you may
        submit a written notification pursuant to the Digital Millennium
        Copyright Act (“DMCA”) (see 17 U.S.C. 512(c)(3) for further information)
        by providing our Copyright Agent (listed below) with the following
        information: (i) an electronic or physical signature of a person
        authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed; (ii) a description of the copyrighted work claimed
        to have been infringed or if multiple copyrighted works are covered by a
        notification, a representative list of such works at our Site; (iii) a
        description of the location on the Services of the allegedly infringing
        material(s); (iv) your address, telephone number, and e-mail address;
        (v) a written statement that you have a good faith belief that use of
        the material(s) in the manner complained of is not authorized by the
        copyright owner, its agent, or the law; and (vi) a written statement
        that the information in the notification is accurate, and under penalty
        of perjury, that you are authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed.
      </Paragraph>
      <Paragraph>
        Our designated Copyright Agent for notice of claims of infringement is:
      </Paragraph>
      <Paragraph>
        Steve Fullbright
        <br />
        Music Audience Exchange
        <br />
        2595 Dallas Parkway Suite 440, Frisco, TX 75034
        <br />
        e-mail: steve@settheset.com
        <br />
        telephone: 844-261-3446
        <br />
      </Paragraph>

      <Paragraph>
        Only notices of alleged copyright infringement should go to the
        Copyright Agent. You acknowledge that if you fail to comply with all of
        the notice requirements of the DMCA, your notice may not be valid.
      </Paragraph>
      <Paragraph>
        If you believe that any User Submission of yours that was removed is not
        infringing, or that you have the appropriate rights from the copyright
        owner or third party, or pursuant to the law, to post and use the
        material in your User Submission, you may send a counter notification
        containing the following information to the Copyright Agent: (i) your
        physical or electronic signature; (ii) identification of the material
        that has been removed or to which access has been disabled and the
        location at which the material appeared before it was removed or
        disabled; (iii) a statement (under penalty of perjury) that you have a
        good faith belief that the content was removed or disabled as a result
        of mistake or a misidentification of the material; and your name,
        address, telephone number, and e-mail address, along with a statement
        that you consent to the jurisdiction of the federal court in the State
        of Texas and a statement that you will accept service of process from
        the person who provided notification of the alleged infringement.
      </Paragraph>
      <Paragraph>
        If a counter notification is received by the Copyright Agent, we shall
        send a copy of such counter notification to the original notifying
        party. The original notifying party shall have ten (10) business days to
        file an action for copyright infringement and seek a court order against
        the content provider or user posting such material. If no such
        infringement action is filed within such 10 business days, we may, in
        its sole discretion, reinstate the removed material or cease disabling
        such material.
      </Paragraph>
      <Paragraph>
        In accordance with the DMCA and other applicable law, we will, in
        appropriate circumstances, terminate access, at our sole discretion, of
        any member or user that we find to be a repeat infringer of others
        copyrights. We may also, in its sole discretion, limit or fully
        terminate access to the Services of any user infringing the intellectual
        property rights of others, regardless of whether such user is repeat
        offender or not.
      </Paragraph>

      <ParagraphTitle>
        Who is responsible for what I see and do on the Services?
      </ParagraphTitle>
      <Paragraph>
        Any information or content publicly posted or privately transmitted
        through the Services is the sole responsibility of the person from whom
        such content originated, and you access all such information and content
        at your own risk, and we aren&rsquo;t liable for any errors or omissions
        in that information or content or for any damages or loss you might
        suffer in connection with it. We cannot control and have no duty to take
        any action regarding how you may interpret and use the Content or what
        actions you may take as a result of having been exposed to the Content,
        and you hereby release us from all liability for you having acquired or
        not acquired Content through the Services. We can&rsquo;t guarantee the
        identity of any users with whom you interact in using the Services and
        are not responsible for which users gain access to the Services.
      </Paragraph>
      <Paragraph>
        You are responsible for all Content you contribute, in any manner, to
        the Services, and you represent and warrant you have all rights
        necessary to do so, in the manner in which you contribute it. You will
        keep all your registration information accurate and current. You are
        responsible for all your activity in connection with the Services.
      </Paragraph>
      <Paragraph>
        The Services may contain links or connections to third party websites or
        services that are not owned or controlled by Set the Set. When you
        access third party websites or use third party services, you accept that
        there are risks in doing so, and that Set the Set is not responsible for
        such risks. We encourage you to be aware when you leave the Services and
        to read the terms and conditions and privacy policy of each third party
        website or service that you visit or utilize.
      </Paragraph>
      <Paragraph>
        Set the Set has no control over, and assumes no responsibility for, the
        content, accuracy, privacy policies, or practices of or opinions
        expressed in any third party websites or by any third party that you
        interact with through the Services. In addition, Set the Set will not
        and cannot monitor, verify, censor or edit the content of any third
        party site or service. By using the Services, you release and hold us
        harmless from any and all liability arising from your use of any third
        party website or service.
      </Paragraph>
      <Paragraph>
        Your interactions with organizations and/or individuals found on or
        through the Services, including payment and delivery of goods or
        services, and any other terms, conditions, warranties or representations
        associated with such dealings, are solely between you and such
        organizations and/or individuals. You should make whatever investigation
        you feel necessary or appropriate before proceeding with any online or
        offline transaction with any of these third parties. You agree that Set
        the Set shall not be responsible or liable for any loss or damage of any
        sort incurred as the result of any such dealings.
      </Paragraph>
      <Paragraph>
        If there is a dispute between participants on this site, or between
        users and any third party, you are solely responsible for it and you
        agree that Set the Set is under no obligation to become involved. In the
        event that you have a dispute with one or more other users, you release
        Set the Set, its officers, employees, agents, and successors from
        claims, demands, and damages of every kind or nature, known or unknown,
        suspected or unsuspected, disclosed or undisclosed, arising out of or in
        any way related to such disputes and/or our Services. If you are a
        California resident, you shall and hereby do waive California Civil Code
        Section 1542, which says:&nbsp;&ldquo;A general release does not extend
        to claims that the creditor or releasing party does not know or suspect
        to exist in his or her favor at the time of executing the release and
        that, if known by him or her, would have materially affected his or her
        settlement with the debtor or released party.&rdquo;
      </Paragraph>

      <ParagraphTitle>Sweepstakes, Contests and Promotions</ParagraphTitle>
      <Paragraph>
        We and third-parties that utilize the Services, such as artists or
        advertisers, may offer sweepstakes, contests, and other &lrm;promotions
        (any, a &ldquo;Promotion&rdquo;) through the Services that may require
        registration. &nbsp;By &lrm;participating in a Promotion, you are
        agreeing to the official rules that govern such third party&rsquo;s
        Promotion, which are separate from these Terms, and which &lrm;may
        contain specific requirements of you, including, allowing the sponsor of
        the Promotion to use &lrm;your name, voice, and/or likeness in
        advertising or marketing associated with the Promotion. &nbsp;If you
        choose to enter a Promotion, you should review the official rules
        associated with the Promotion, which are separate from these Terms.
        &nbsp;If &lrm;you choose to enter a Promotion through your use of the
        Services, certain personal information may be disclosed to third parties
        or the &lrm;public in connection with the administration of such
        Promotion, including, in connection with &lrm;winner selection, prize
        fulfillment, and as required by law or permitted by the
        Promotion&rsquo;s official &lrm;rules, such as on a winners list. Set
        the Set does not sponsor Promotions except where we specifically say so.
      </Paragraph>

      <ParagraphTitle>
        Will Set the Set ever change the Services?
      </ParagraphTitle>
      <Paragraph>
        We&rsquo;re always trying to improve the Services, so they may change
        over time. We may suspend or discontinue any part of the Services, or we
        may introduce new features or impose limits on certain features or
        restrict access to parts or all of the Services. We&rsquo;ll try to give
        you notice when we make a material change to the Services that would
        adversely affect you, but this isn&rsquo;t always practical. Similarly,
        we reserve the right to remove any Content from the Services at any
        time, for any reason (including, but not limited to, if someone alleges
        you contributed that Content in violation of these Terms), in our sole
        discretion, and without notice.
      </Paragraph>
      <ParagraphTitle>Do the Services cost anything?</ParagraphTitle>
      <Paragraph>
        The Company Services are currently free, but we reserve the right to
        charge for certain or all Services in the future. We will notify you
        before any Services you are then using begin carrying a fee, and if you
        wish to continue using such Services, you must pay all applicable fees
        for such Services.
      </Paragraph>

      <ParagraphTitle>
        What if I want to stop using the Services?
      </ParagraphTitle>
      <Paragraph>
        You&rsquo;re free to do that at any time, by contacting us at
        privacy@settheset.com; please refer to our , as well as the licenses
        above, to understand how we treat information you provide to us after
        you have stopped using our Services.
      </Paragraph>
      <Paragraph>
        Set the Set is also free to terminate (or suspend access to) your use of
        the Services or your account, for any reason in our discretion,
        including your breach of these Terms. Set the Set has the sole right to
        decide whether you are in violation of any of the restrictions set forth
        in these Terms.
      </Paragraph>
      <Paragraph>
        Account termination may result in destruction of any Content associated
        with your account, so keep that in mind before you decide to terminate
        your account. We will try to provide advance notice to you prior to our
        terminating your account so that you are able to retrieve any important
        User Submissions you may have stored in your account (to the extent
        allowed by law and these Terms), but we may not do so if we determine it
        would be impractical, illegal, not in the interest of someone&rsquo;s
        safety or security, or otherwise harmful to the rights or property of
        Company.
      </Paragraph>
      <Paragraph>
        If you have deleted your account by mistake, contact us immediately at
        privacy@settheset.com&ndash; we will try to help, but unfortunately, we
        can&rsquo;t promise that we can recover or restore anything.
      </Paragraph>

      <ParagraphTitle>
        Provisions that, by their nature, should survive termination of these
        Terms shall survive termination. By way of example, all of the following
        will survive termination: any obligation you have to pay us or indemnify
        us, any limitations on our liability, any terms regarding ownership or
        intellectual property rights, and terms regarding disputes between us.
      </ParagraphTitle>
      <Paragraph></Paragraph>

      <ParagraphTitle>What else do I need to know?</ParagraphTitle>
      <Paragraph>
        Warranty Disclaimer. You agree that your use of the Services shall be at
        your own risk. Neither Set the Set nor its licensors or suppliers makes
        any representations or warranties concerning any content contained in or
        accessed through the Services, and we will not be responsible or liable
        for the accuracy, copyright compliance, legality, or decency of material
        contained in or accessed through the Services. We (and our licensors and
        suppliers) make no representations or warranties regarding suggestions
        or recommendations of services or products offered or purchased through
        the Services. THE SERVICES AND CONTENT ARE PROVIDED BY SET THE SET (AND
        ITS LICENSORS AND SUPPLIERS) ON AN &ldquo;AS-IS&rdquo; BASIS, WITHOUT
        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
        LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL
        BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON
        HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT
        APPLY TO YOU.
      </Paragraph>
      <Paragraph>
        Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE
        LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING,
        WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE)
        SHALL SET THE SET (OR ITS LICENSORS, OFFICERS, DIRECTORS, EMPLOYEES,
        AFFILIATES, SUBSIDIARIES, AGENTS, PARTNERS, OR SUPPLIERS) BE LIABLE TO
        YOU OR TO ANY OTHER PERSON FOR (A) ANY DIRECT, INDIRECT, SPECIAL,
        INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR
        LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF
        RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE
        AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID
        BY YOU TO COMPANY IN CONNECTION WITH THE SERVICES IN THE TWELVE (12)
        MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND
        OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS
        MAY NOT APPLY TO YOU.
      </Paragraph>
      <Paragraph>
        YOU SPECIFICALLY ACKNOWLEDGE THAT WE WILL NOT BE LIABLE FOR USER
        SUBMISSIONS OR THE DEFAMATORY, &lrm;OFFENSIVE, OR ILLEGAL CONDUCT OF ANY
        THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE &lrm;FOREGOING
        RESTS ENTIRELY WITH YOU.&lrm;
      </Paragraph>
      <Paragraph>
        Indemnity. To the fullest extent allowed by applicable law, you agree to
        indemnify and hold Set the Set, its affiliates, subsidiaries, officers,
        agents, employees, and partners harmless from and against any and all
        claims, liabilities, damages (actual and consequential), losses and
        expenses (including attorneys&rsquo; fees) arising from or in any way
        related to any third party claims relating to (a) your use of the
        Services (including any actions taken by a third party using your
        account), and (b) your violation of these Terms or any other legal
        notices or guidelines. In the event of such a claim, suit, or action
        (&ldquo;Claim&rdquo;), we will attempt to provide notice of the Claim to
        the contact information we have for your account (provided that failure
        to deliver such notice shall not eliminate or reduce your
        indemnification obligations hereunder).
      </Paragraph>
      <Paragraph>
        Assignment. You may not assign, delegate or transfer these Terms or your
        rights or obligations hereunder, or your Services account, in any way
        (by operation of law or otherwise) without Set the Set&rsquo;s prior
        written consent. We may transfer, assign, or delegate these Terms and
        our rights and obligations without consent or other restriction.
      </Paragraph>
      <Paragraph>
        Choice of Law; Arbitration. These Terms are governed by and will be
        construed under the laws of the State of Texas, without regard to the
        conflicts of laws provisions thereof. Any dispute arising from or
        relating to the subject matter of these Terms shall be finally settled
        in Dallas County, Texas, in English, in accordance with the Streamlined
        Arbitration Rules and Procedures of Judicial Arbitration and Mediation
        Services, Inc. (&#39;JAMS&#39;) then in effect, by one commercial
        arbitrator with substantial experience in resolving intellectual
        property and commercial contract disputes, who shall be selected from
        the appropriate list of JAMS arbitrators in accordance with such Rules.
        Judgment upon the award rendered by such arbitrator may be entered in
        any court of competent jurisdiction. Notwithstanding the foregoing
        obligation to arbitrate disputes, each party shall have the right to
        pursue injunctive or other equitable relief at any time, from any court
        of competent jurisdiction. For all purposes of these Terms, the parties
        consent to exclusive jurisdiction and venue in the state or federal
        courts located in, respectively, Dallas County, Texas, or the Northern
        District of Texas. Any arbitration under these Terms will take place on
        an individual basis: class arbitrations and class actions are not
        permitted. YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS,
        YOU AND COMPANY ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO
        PARTICIPATE IN A CLASS ACTION.
      </Paragraph>
      <Paragraph>
        Miscellaneous. You will be responsible for paying, withholding, filing,
        and reporting all taxes, duties, and other governmental assessments
        associated with your activity in connection with the Services, provided
        that the Company may, in its sole discretion, do any of the foregoing on
        your behalf or for itself as it sees fit. The failure of either you or
        us to exercise, in any way, any right herein shall not be deemed a
        waiver of any further rights hereunder. If any provision of these Terms
        is found to be unenforceable or invalid, that provision will be limited
        or eliminated, to the minimum extent necessary, so that these Terms
        shall otherwise remain in full force and effect and enforceable. You and
        Set the Set agree that these Terms are the complete and exclusive
        statement of the mutual understanding between you and Set the Set, and
        that it supersedes and cancels all previous written and oral agreements,
        communications and other understandings relating to the subject matter
        of these Terms. You hereby acknowledge and agree that you are not an
        employee, agent, partner, or joint venture of Set the Set, and you do
        not have any authority of any kind to bind Set the Set in any respect
        whatsoever. You and Set the Set agree there are no third party
        beneficiaries intended under these Terms.
      </Paragraph>
      <ParagraphTitle>
        Exclusions and Limitations; Consumer Protection Notice
      </ParagraphTitle>
      <Paragraph>
        If you are a consumer, the provisions in these Terms are intended to be
        only as broad and inclusive &lrm;as is permitted by the laws of your
        State of residence. If you are a New Jersey consumer, the terms &lrm;of
        Sections entitled Warranty Disclaimer, Limitation of Liability,
        Indemnity; Choice of Law; Arbitration; and Miscellaneous do not limit or
        &lrm;waive your rights as a consumer under New Jersey law and the
        provisions in these Terms as applied &lrm;to you are intended to be only
        as broad and inclusive as is permitted by the laws of the State of
        &lrm;New Jersey. In any event, Set the Set reserves all rights, defenses
        and permissible limitations under &lrm;the law of your state of
        residence.&lrm;
      </Paragraph>
      <ParagraphTitle>Contact</ParagraphTitle>
      <Paragraph>
        If you have any questions, complaints, or claims with respect to the
        Services, you may contact us at: privacy@settheset.com
      </Paragraph>
      <Paragraph></Paragraph>
      <Paragraph>81597609v.7</Paragraph>
    </LegalContainer>
  );
};
