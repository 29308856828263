import React from "react";
import styled from "styled-components";
import { ConnectedShowSelector, Header } from "./";
export const MobileShows = () => {
  return (
    <PageContainer>
      <Header simple />
      <SelectorContainer>
        <ConnectedShowSelector />
      </SelectorContainer>
    </PageContainer>
  );
};

const SelectorContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
`;

const PageContainer = styled.div``;
