import React from "react";
import { Header } from "./";
import { Link } from "Components";
import styled from "styled-components";
import { P, ButtonPrimary, ButtonPrimaryInternal, FlexBox } from "notes";
import { SurveyConsumer } from "Providers";
import { FormattedMessage } from "react-intl";
import { ReactComponent as DarkLogoSmall } from "Images/STSLogo-Button.svg";
import { customParse as parse } from "Components";

const LandingContainer = styled(FlexBox)`
  flex-direction: column;
`;

const LinkButton = styled(ButtonPrimaryInternal)`
  text-decoration: none;
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
`;

const Content = styled(FlexBox)`
  flex-direction: column;
  padding: 16px;
  padding-top: 0;
`;

export const MobileLanding = () => (
  <SurveyConsumer>
    {({
      state: {
        collector: {
          setlist: { concerts }
        }
      },
      getCustomization
    }) => (
      <LandingContainer>
        <Header marginBelow />
        <Content>
          <Link to={concerts && concerts.length > 0 ? "shows" : "songs"}>
            <LinkButton data-javelin-name={`mobile-landing-set-the-set`}>
              {/* <FormattedMessage
                id="label.create_set_list"
                defaultMessage="Create Set List"
              /> */}
              <DarkLogoSmall />
            </LinkButton>
          </Link>
          <Description>{parse(getCustomization("welcomeText"))}</Description>
        </Content>
      </LandingContainer>
    )}
  </SurveyConsumer>
);

const Description = styled(P)`
  color: ${props =>
    props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
  margin-top: 32px;
`;
