import React, {useContext, Fragment} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import SelectList from '../inputs/SelectList';
import Checkboxes from '../inputs/Checkboxes';
import CheckboxMatrix from '../inputs/CheckboxMatrix';
import TextInput from '../inputs/TextInput';
import TextArea from '../inputs/TextArea';
import RequiredField from 'Components/RequiredField';
import {SurveyContext} from 'Providers';
import {QuestionTypes} from 'modules/constants';
import MaxLabel from './MaxLabel';
import {useIntl} from 'react-intl';
import PopOver from './PopOver';
import {Payment} from './Payment';

const Question = ({data = null}) => {
  const {
    state: {locale, formErrors},
  } = useContext(SurveyContext);

  const {formatMessage} = useIntl();

  console.log('QUESTION', data);

  /**
   * Display question element based on type
   */
  const display = () => {
    const answers = _.get(data, `translations.${locale}.Option`, []);

    const error = formErrors && formErrors.indexOf(data.id) > -1;

    const inline = _.get(data, 'inline', false);

    switch (parseInt(data.element_type, 10)) {
      case QuestionTypes.CHECKBOX:
        return (
          <Checkboxes data={data} error={error} key={`__checkbox_${data.id}`} />
        );
      case QuestionTypes.TEXT:
        return (
          <FieldWrapper inline={inline}>
            <TextInput data={data} error={error} />
          </FieldWrapper>
        );
      case QuestionTypes.TEXTAREA:
        return (
          <FieldWrapper inline={inline}>
            <TextArea data={data} error={error} />
          </FieldWrapper>
        );
      case QuestionTypes.SELECT:
      case QuestionTypes.SELECTLIST:
        return (
          <FieldWrapper>
            <SelectList data={data} error={error} />
          </FieldWrapper>
        );
      /*
            case QuestionTypes.AUTOCOMPLETE:
                return <AutoComplete data={data} error={error}/>
            case QuestionTypes.RADIO:
                // Radio button group
                // Mobile: accordion containing radio buttons
                return <RadioButtonRows data={data} error={error}/>
            case QuestionTypes.RANGE:
                // Select list with range
                return <RangeSelectList data={data} error={error}/>
                */
      case QuestionTypes.PAYMENT:
        return <Payment />;
      default:
        break;
    }
  };

  const options = () => {
    const options = _.get(data, `translations.${locale}.Option`, []);

    if (!options || !options.length) return null;

    let output = [];

    options.map((option, index) => {
      switch (option.type) {
        case 'popover':
          output.push(
            <div
              key={`_option_${data.id}_${index}`}
              style={{marginTop: '10px'}}
            >
              <PopOver data={option} />
            </div>
          );
          break;
        default:
          break;
      }
    });

    return output;
  };

  const render = () => {
    if (!data) return null;

    const validations = _.get(data, 'validations', null);
    const validators = _.get(data, 'validators', []);

    let message = _.get(
      data,
      `translations.${locale}.errorMessage`,
      formatMessage({
        id: 'error.field_required',
        defaultMessage: 'This field is required.',
      })
    );

    if (!message) {
      validators.map(validator => {
        switch (validator) {
          case 'zip':
            message = formatMessage({
              id: 'error.zip_code',
              defaultMessage:
                'Required field; please provide your local ZIP or Postal Code.',
            });
            break;
          case 'year':
            message = formatMessage({
              id: 'error.birth_year',
              defaultMessage:
                'Year must be 4 digits and you must be at least 13 years old.',
            });
            break;
          case 'email':
            message = formatMessage({
              id: 'error.email',
              defaultMessage: 'Invalid email address.',
            });
            break;
          default:
            break;
        }
      });
    }

    const number = _.get(data, 'number', null);

    const required = _.get(data, 'validations.required', false);

    const title = _.get(data, `translations.${locale}.title`, '');

    const error = formErrors && formErrors.indexOf(data.id) > -1;

    const inline = _.get(data, 'inline', false);

    return (
      <FormField
        inline={inline}
        className={inline ? 'inline-field' : undefined}
      >
        {parseInt(data.element_type, 10) !== QuestionTypes.CHECKBOX && (
          <MaxLabel text={title} number={number} required={required} />
        )}
        <RequiredField message={message} error={error} inline={inline}>
          {display()}
        </RequiredField>
        {options()}
      </FormField>
    );
  };

  return render();
};

export default Question;

const FieldWrapper = styled.div`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  @media only screen and ${props => props.theme.media.large} {
    max-width: 392px;
  }
`;

const FormField = styled.div`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  &.inline-field {
    max-width: 50%;
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.inline-field + &.inline-field {
    input {
      border-left-width: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  @media only screen and ${props => props.theme.media.large} {
    width: auto;
  }
`;
