import styled from "styled-components";
import React, { useContext, useState } from "react";
import { SurveyContext } from "Providers";
import { H3 } from "notes";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

export const SongList = ({ songs }) => {
  const { flatSongs, responses } = useContext(SurveyContext);
  const filteredSongs = flatSongs
    .filter(({ id }) => songs.includes(id))
    .sort(
      ({ id: idA }, { id: idB }) => songs.indexOf(idA) - songs.indexOf(idB)
    );

  // TODO: Update this function after context is fixed.
  const firstName = _.get(responses(), "firstName");
  const [name, setName] = useState(
    !!firstName && firstName !== "" ? `${firstName}'s` : "Your"
  );

  const albumImage = song => {
    return !!song.album.image
      ? song.album.image
      : song.album.images && song.album.images.length > 0
      ? song.album.images[0].url
      : null;
  };

  return (
    <ListContainer>
      {!!name && (
        <Title>
          <FormattedMessage
            id="header.user_song_list"
            values={{
              name: name
            }}
            defaultMessage={`${name} Requests`}
          />
        </Title>
      )}
      {!!filteredSongs &&
        filteredSongs.map(song => (
          <SongContainer>
            <AlbumCover image={albumImage(song)} />
            <SongName>{song.name}</SongName>
            <AlbumName>{song.album.name}</AlbumName>
          </SongContainer>
        ))}
    </ListContainer>
  );
};

const Title = styled(H3)`
  margin-top: 24px;
`;

const ListContainer = styled.div`
  border-top: 2px solid #d2d5d7;
  margin: 50px auto;
  max-width: 1016px;
  text-align: left;
  width: 100%;
`;

const SongContainer = styled.div`
  border-bottom: 1px solid #e6e8ea;
  display: block;
  position: relative;
  padding: 16px 0 16px 68px;
  margin: 0;
`;

const AlbumCover = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  border-radius: 4px;
  height: 44px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 44px;
`;

const SongName = styled.span`
  display: block;
  ${props => props.theme.fonts.default}
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
`;

const AlbumName = styled.span`
  display: block;
  ${props => props.theme.fonts.default}
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
`;
