import _ from "lodash";

const reducer = (currentState, payload) => {
  // Objects are the same, just return current state
  if (_.isEqual(currentState, payload)) {
    return currentState;
  }

  return _.extend({}, currentState, payload);
};

export default reducer;
