// This is used as the source of a hidden iFrame on the Collector page
// and controls the FB login workflow.  It is triggered by a message
// from the parent window, then replies back via message.

import React, { useEffect, useContext } from "react";
import Api from "modules/Api";
import { SurveyContext } from "Providers";

const FacebookAuth = () => {
  const {
    saveResponses,
    state: { instance, collector }
  } = useContext(SurveyContext);

  useEffect(() => {
    window.addEventListener("message", function(e) {
      if (e.data) {
        try {
          if (e.data === "login") {
            login(e.source, e.origin);
          }
        } catch (err) {
          console.log(err.message);
          window.close();
        }
      }
    });
  }, []);

  const login = (source, origin) => {
    try {
      window.FB.login(
        response => {
          if (response.authResponse) {
            // save share event to responses db for reporting
            saveResponses([{ questionId: "fb_auth", value: 1 }]);
            window.FB.api("/me", { fields: "name,email" }, response => {
              source.postMessage(JSON.stringify({ fbauth: response }), origin);
              window.close();
            });
          } else {
            window.close();
          }
        },
        { scope: "public_profile,email" }
      );
    } catch (err) {
      console.log("FB Error", err);
    }
  };

  return <div />;
};

export default FacebookAuth;
