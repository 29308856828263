export default {
  "label.create_set_list": "Create Set List",
  "prompt.which_concert": "Which of our concerts are you planning to attend?",
  "label.upcoming_concerts": "Upcoming concerts near:",
  "label.which_songs":
    "Which of our songs would you like us to play at your concert?",
  "label.max_select": "(select up to {max})",
  "label.continue": "Continue",
  "message.no_songs_selected": "You have not selected any songs yet...",
  "label.selected_songs": "Selected Songs",
  "button.submit": "Submit",
  "button.submitting": "Submitting",
  "button.previous": "Previous",
  "button.next": "Next",
  "button.done": "Done",
  "error.not.found":
    "We're sorry, but we could not find the requested survey. Please check the survey link and try again.",
  "label.not_attending": "I'm not attending a concert",
  "label.still_build": "(But I still want to build a custom playlist)",
  "label.show_more_concerts": "Show additional concerts",
  "label.song_search": "Search for a song by name or album...",
  "label.no_songs_found":
    "No songs matching “{search}” were found. Please check your spelling or try a new search...",
  "label.featured_songs": "Featured Songs",
  "label.popular_songs": "Popular on Spotify",
  "label.learn_more": "Learn More",
  "label.terms": "Terms & Conditions",
  "label.privacy": "Privacy Policy",
  "message.default_survey_intro":
    "You’re almost done! Just tell us a bit about yourself before submitting your requests...",
  "label.select": "Please Select",
  "error.field_required": "This field is required",
  "error.form":
    "Please review the highlighted errors before attempting your submission again.",
  "label.artist_privacy": "{artist}'s Privacy Policy",
  "label.artist_terms": "{artist}'s Terms & Conditions",
  "spotify.playlist":
    "Don't forget to turn these songs into a custom Spotify playlist!",
  "spotify.listen": "Listen on Spotify",
  "spotify.share": "Share my Playlist",
  "spotify.share.text":
    "I just created the perfect playlist for {artist} on Set The Set!",
  "label.max_songs_exceeded": "Select no more than {max_songs} songs",
  "message.no_songs_selected": "You have not selected any songs yet...",
  "label.song_count_multiple": "{selected_count} Songs Selected",
  "label.song_count_single": "{selected_count} Song Selected",
  "label.song_count_max_single": "{selected_count} of 1 Song Selected",
  "label.song_count_max_multiple":
    "{selected_count} of {max_songs} Songs Selected",
  "label.no_songs_selected": "No Songs Selected",
  "header.user_song_list": "{name} Requests",
  "label.optional": "optional"
};
