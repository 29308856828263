import React from "react";
import { P, FlexBox, IconRemove, IconRemoveReversed } from "notes";
import styled from "styled-components";

const Container = styled(FlexBox)`
  align-items: center;
  justify-content: flex-start;
  margin: 4px 0;
`;

const HoverIcon = styled(IconRemove)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 10px;
  &:hover g {
    fill: ${props => props.theme.colors.removeRed};
  }
`;

const ClippedP = styled(P)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Song = ({ handleRemove, name }) => {
  return (
    <Container>
      <HoverIcon onClick={handleRemove} />
      <ClippedP>{name}</ClippedP>
    </Container>
  );
};
