import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import { P, H4, Link } from "notes";
import { SurveyContext } from "Providers";
import TicketImage from "Images/TicketImage.png";
import { formatDate } from "modules";

const title_string =
  "Still need to purchase tickets for your concerts? You can get them here!";

export const Tickets = () => {
  const {
    state: {
      collector: {
        setlist: { concerts }
      }
    },
    shows
  } = useContext(SurveyContext);

  const filteredShows =
    !!concerts && concerts.length > 0
      ? concerts.filter(
          ({ id, ticketUrl }) => shows.includes(id) && !!ticketUrl
        )
      : [];

  return (
    filteredShows.length > 0 && (
      <TicketContainer>
        <ImageContainer>
          <TicketStyle src={TicketImage} />
        </ImageContainer>
        <Content>
          {filteredShows.length > 1 ? (
            <Fragment>
              <PurchaseTitle>{title_string}</PurchaseTitle>
              {filteredShows.map(({ date, ticketUrl, venue: { name } }) => (
                <MultiTicketContainer>
                  <Ticket>
                    <H4>{name}</H4>
                    <ConcertDate>({formatDate(date)})</ConcertDate>
                  </Ticket>
                  <StyledLink target="_blank" href={ticketUrl}>
                    Buy Tickets
                  </StyledLink>
                </MultiTicketContainer>
              ))}
            </Fragment>
          ) : (
            <Ticket>
              <P>
                Still need a ticket to {filteredShows[0].venue.name} on
                <ConcertDate>{formatDate(filteredShows[0].date)}?</ConcertDate>
              </P>
              <StyledLink target="_blank" href={filteredShows[0].ticketUrl}>
                Buy tickets
              </StyledLink>
            </Ticket>
          )}
        </Content>
      </TicketContainer>
    )
  );
};

const TicketContainer = styled.div`
  background-color: transparent;
  border-color: ${props => props.theme.colors.action};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  padding: 16px 32px;
  margin: 0 auto;
  max-width: 1016px;
  width: 100%;

  @media only screen and ${props => props.theme.media.large} {
    background-color: #fffaeb;
    border-color: #ffeaad;
    border-radius: 0;
    flex-flow: row nowrap;
  }
`;

const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  min-height: 100%;
  @media only screen and ${props => props.theme.media.large} {
    margin-right: 40px;
    margin-bottom: 0;
  }
`;

const TicketStyle = styled.img`
  height: 80px;
  width: 80px;
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  min-height: 100%;
  justify-content: center;
  width: 100%;
`;

const PurchaseTitle = styled(P)`
  margin: 16px 0;
`;

const MultiTicketContainer = styled.div`
  border-bottom: 1px solid #ffeaad;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;
  &:last-child {
    border-bottom: 0;
  }
  @media only screen and ${props => props.theme.media.large} {
    flex-flow: row wrap;
  }
`;

const Ticket = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  @media only screen and ${props => props.theme.media.large} {
    flex-flow: row nowrap;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 8px;
  @media only screen and ${props => props.theme.media.large} {
    margin: 0;
  }
`;

const ConcertDate = styled(P)`
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
`;
