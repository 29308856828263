import styled from "styled-components";
import { P } from "notes";

export default styled(P)`
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
`;
