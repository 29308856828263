import React, { useState, useContext } from "react";
import { TextArea } from "notes";
import { SurveyContext } from "Providers";
import _ from "lodash";
import styled from "styled-components";

export default ({ data }) => {
  const {
    onResponse,
    state: { locale }
  } = useContext(SurveyContext);

  const [value, setValue] = useState(data.value);

  const limit = data.limit || 512;

  const handleChange = t => {
    let result = typeof t === "object" ? t.target.value : t;
    if (result.length > limit) {
      result = result.substring(0, limit);
    }
    setValue(result);
    onResponse(data.id, result);
  };

  const placeholder = _.get(data, `translations.${locale}.placeholder`, "");

  return (
    <>
      <TextArea name={data.id} onChange={handleChange} value={value} />
      <CountContainer>
        <p>
          {value ? value.length : "0"} of {limit} chars.
        </p>
      </CountContainer>
    </>
  );
};

const CountContainer = styled.div`
  text-align: right;
  padding: 5px 0;
  ${props => props.theme.fonts.default};
  font-size: 0.85rem;
`;
