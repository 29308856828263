import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { Checkbox } from "notes";
import { SurveyContext } from "Providers";
import { customParse as parse } from "Components";

const Checkboxes = ({ data = { id: "", checked: false } }) => {
  const [state, dispatch] = useState({ checked: data.checked });

  const {
    onResponse,
    state: { locale }
  } = useContext(SurveyContext);

  useEffect(() => {
    onResponse(data.id, data.checked);
  }, [data]);

  const handleClick = checked => {
    dispatch({ checked: checked });
    onResponse(data.id, checked);
  };

  const render = () => {
    const { checked } = state;
    const title = parse(_.get(data, `translations.${locale}.title`, ""));
    return <Checkbox title={title} checked={checked} onChange={handleClick} />;
  };

  return render();
};

export default Checkboxes;
