import React from "react";
import styled from "styled-components";
import { Label } from "notes";
import { FormattedMessage } from "react-intl";

const MaxLabel = ({ text = "", number = null, required = false }) =>
  text && (
    <StyledLabel>
      {" "}
      {number} {text}{" "}
      {required ? (
        ""
      ) : (
        <span>
          (<FormattedMessage id="label.optional" defaultMessage="optional" />)
        </span>
      )}{" "}
    </StyledLabel>
  );

export default MaxLabel;

const StyledLabel = styled(Label)`
  display: ${props => (props.inline ? "inline-block" : "block")};
  color: ${props =>
    props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
  margin-top: 40px;
  margin-bottom: 4px;
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => props.theme.lightDefault};
  }
`;
