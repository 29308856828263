import React, {Component} from 'react';
import _ from 'lodash';
import {QuestionTypes} from '../../../modules/constants';
import {SurveyContext} from 'Providers';
//import ReactPixel from "react-facebook-pixel";
import styled from 'styled-components';
import {checkValidators} from 'modules/validate';

const FORWARD = 1;
const BACK = -1;

const initialState = {
  loading: false,
  asset: null,
};

const Banner = styled.div`
  width: 100%;
  min-height: 300px;
  background: ${props =>
    props.image ? `url(${props.image}) no-repeat top` : 'none'};
  background-size: contain;
`;

const BannerText = styled.div`
  margin-top: 5%;
  width: 40%;
  height: 100%;
  display: inline-block;
`;

const BannerHeader = styled.h2`
  margin-bottom: 20px !important;
`;

class Layout extends Component {
  static contextType = SurveyContext;

  constructor(props) {
    super(props);
    this.state = _.extend({}, initialState);
  }

  header() {
    const {
      getCurrentPage,
      state: {
        collector: {artist, theme},
      },
    } = this.context;
    const page = getCurrentPage();
    const banner = _.get(theme, 'banner');
    const welcomeText = _.get(page, 'intro');

    return (
      <Banner image={banner.desktop}>
        <BannerText>
          <BannerHeader as="h2">{_.get(artist, 'name', '')}</BannerHeader>
          {welcomeText}
        </BannerText>
      </Banner>
    );
  }

  /**
   * @TODO: Refactor to use Joi
   */
  validate() {
    // validate
    let errors = [];

    const {setState, currentResponses, getCurrentPage, skipZip} = this.context;

    const page = getCurrentPage();

    const sections = _.get(page, 'Section', []);

    // Get current responses for this page from the SurveyContext
    const responses = currentResponses();

    const response_keys = Object.keys(responses);

    sections
      .filter(section => !!section)
      .map(section => {
        const questions = _.get(section, 'Question', []);

        // Now validate answers based on question type and any validators
        // defined for the question
        questions
          .filter(({status}) => status === 1)
          .map(question => {
            const {id, element_type, source} = question;

            if (question.id === 'zip' && skipZip) {
              return;
            }

            const required = _.get(question, 'validations.required', false);

            let validators = _.get(question, 'validators', []);

            let valid = true;
            let value;

            switch (parseInt(element_type, 10)) {
              //case QuestionTypes.CHECKBOX:
              //    break
              //case QuestionTypes.TEXT:
              //    break
              //case QuestionTypes.SELECT:
              //    break
              //case QuestionTypes.SELECTLIST:
              //    break
              case QuestionTypes.AUTOCOMPLETE:
                value = response_keys
                  .filter(key => key.indexOf(`${id}_${source}`) === 0)
                  .filter(key => responses[key] !== null)
                  .join('');
                break;
              //case QuestionTypes.RADIO:
              //    break
              //case QuestionTypes.RANGE:
              //    break
              default:
                value = _.get(responses, id, '');
                break;
            }

            if (!!value) {
              if (!Array.isArray(validators)) {
                validators = [validators];
              }

              if (validators.length) {
                valid = checkValidators(validators, value, null, responses);
              } else {
                valid = value !== '';
              }
            } else {
              valid = false;
            }

            if ((required && !valid) || (!!value && !valid)) {
              errors.push(question.id);
            }
          });
      });

    setState({formErrors: errors});

    if (errors && errors.length) {
      window.scrollTo(0, 0);
    }

    return errors.length === 0;
  }

  /**
   * Move to next (or previous) page in the Survey.
   * Answers are validated before being submitted.
   * @param {*} dir
   */
  move(dir = 0, validateForm = true) {
    if (dir === 0) return;

    this.setState({loading: true});

    if (validateForm && !this.validate()) {
      this.setState({loading: false});
      return;
    }

    const {
      state: {ref, collector, instance, current_page, max_page},
      submitSurveyAnswers,
      setCurrentPage,
      updateInstance,
      setState,
    } = this.context;

    let index = current_page;

    submitSurveyAnswers()
      .then(({errors}) => {
        window.scrollTo(0, 0);

        if (errors && errors.length) {
          console.error(errors);
          this.setState({
            loading: false,
          });
          setState({
            formErrors: [
              'There was a problem submitting your responses. Please try again',
            ],
          });
          return;
        }

        switch (dir) {
          case BACK:
            // move to previous page
            if (index > 0) index--;
            break;
          case FORWARD:
            // move to next page or thank you page
            if (index === max_page) {
              // update survey status to complete
              //updateInstance();
              /*
              if (process.env.REACT_APP_STAGE === "production") {
                ReactPixel.track("SubmitApplication", {
                  instance: instance.id,
                  collector: collector.id,
                  referrer: ref
                });
              }
              */
            }
            index++;
            break;
          default:
            break;
        }
        setCurrentPage(index);
        this.setState({loading: false});
      })
      .catch(err => {
        // error conditions are already handled
        this.setState({loading: false});
        console.error(err);
      });
  }

  // this function (and others that will probably be needed)
  // could live in a 'Collector' class with functions to retrieve
  // customized settings
  getProperties() {
    const {
      state: {collector},
    } = this.context;
    const asset_id = _.get(collector, 'asset_id', null);
    const colors = _.get(collector, 'colors.user_colors', {});
    const properties = _.get(collector, 'properties', null);
    const asset =
      asset_id && properties
        ? _.find(
            _.get(properties, 'fillable', []),
            ({name}) => name === asset_id
          )
        : null;
    return {properties, asset, colors};
  }

  render() {
    return null;
  }
}

export default Layout;
