const formatSongs = (selections, albums) => {
  // Go through song selection id list and map them to album and song title
  /*
  let songResults = [];

  selections.forEach(songId => {
    albums.forEach(album => {
      album.songs
        .filter(({ id }) => id === songId)
        .forEach(song => {
          songResults.push({
            id: song.id,
            name: song.name,
            album: {
              id: album.id,
              name: album.name
            }
          });
        });
    });
  });

  return songResults;
  */

  //just return song id list
  return selections;
};

export default formatSongs;
