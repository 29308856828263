import {merge, cloneDeep} from 'lodash';

const handleResponse = response => {
  try {
    return response.json();
  } catch (err) {
    return {errors: [err.message]};
  }
};

const handleError = err => {
  throw err;
};

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const Api = {
  exec: (method, url, options = {}) => {
    const fetchOptions = merge(cloneDeep(defaultOptions), cloneDeep(options), {
      method: method,
    });
    return fetch(url, fetchOptions)
      .then(handleResponse)
      .catch(handleError);
  },
  get: (url, options) => Api.exec('GET', url, options),
  post: (url, payload, options = {}) =>
    Api.exec('POST', url, {...options, body: JSON.stringify(payload)}),
  patch: (url, payload, options = {}) =>
    Api.exec('PATCH', url, {...options, body: JSON.stringify(payload)}),
  search: (source, term) => {
    let url;
    switch (source) {
      case 'venue':
        return process.env.REACT_APP_ENDPOINT_VENUE;
      case 'artist':
        return process.env.REACT_APP_ENDPOINT_ARTIST;
    }
    return Api.get(url.replace(/:term/gi, term));
  },
  getSpotifyUser: token =>
    Api.get('https://api.spotify.com/v1/me', {
      headers: {Authorization: `Bearer ${token}`},
    }),
  createPlaylist: (token, id, name) =>
    Api.post(
      `https://api.spotify.com/v1/users/${id}/playlists`,
      {name: name, public: true},
      {headers: {Authorization: `Bearer ${token}`}}
    ),
  addTracks: (token, id, tracks) =>
    Api.post(
      `https://api.spotify.com/v1/playlists/${id}/tracks`,
      {uris: tracks},
      {headers: {Authorization: `Bearer ${token}`}}
    ),
};

export default Api;
