import React, {useContext, Fragment} from 'react';
import styled from 'styled-components';
import {SurveyContext} from 'Providers';
import {withDefaultMedia, H1, Subheader, FlexBox, P} from 'notes';
import {Logo} from 'Components';
import _ from 'lodash';
import {customParse as parse} from 'Components';

export const Header = withDefaultMedia(
  ({matches, simple, thankYou = false, marginBelow = false, ...props}) => {
    const {
      state: {
        locale,
        collector: {
          artist,
          setlist: {tour, customizations},
          theme: {banner, colors},
        },
      },
      getCustomization,
    } = useContext(SurveyContext);

    // set images to use based on the artist object...
    // Artist object updated to use a single image
    // with fallback to original object with image array
    const heroImage = !!artist
      ? !!artist.image
        ? artist.image
        : artist.images && artist.images.length
        ? artist.images[0].url
        : null
      : null;
    const mobileHero = getCustomization('mobileHero');

    const showArtistName =
      customizations && customizations.hasOwnProperty('showArtistName')
        ? customizations.showArtistName
        : true;

    const showTourName =
      customizations && customizations.hasOwnProperty('showTourName')
        ? customizations.showTourName
        : true;

    const introText = thankYou
      ? getCustomization('thankYouText')
      : getCustomization('welcomeText');

    const label = getCustomization(
      matches.large ? 'desktopTitle' : 'mobileTitle'
    );

    // Full banner override
    if (banner) {
      return (
        <Fragment>
          <HeaderBackground fill={banner.fill} marginBelow={marginBelow}>
            <HeaderImage src={matches.large ? banner.desktop : banner.mobile} />
            {}
            {label && <BannerTitle color={colors.header}>{label}</BannerTitle>}
            {showTourName && tour && tour.name && (
              <Subitle>{tour.name}</Subitle>
            )}
          </HeaderBackground>
          {introText ? (
            thankYou ? (
              <ThankYouDescription>{parse(introText)}</ThankYouDescription>
            ) : matches.large ? (
              <AltDescription>{parse(introText)}</AltDescription>
            ) : null
          ) : null}
        </Fragment>
      );
    }

    // Default header
    return (
      <HeaderContainer simple={!!simple} {...props}>
        {!simple && (
          <ContentContainer>
            {matches.large && (
              <LogoContainer>
                <Logo />
              </LogoContainer>
            )}
            {label && <Title>{label}</Title>}
            {showTourName && <Subitle>{!!tour ? tour.name : null}</Subitle>}
            {!banner &&
              introText &&
              (thankYou ? (
                <ThankYouDescription>{parse(introText)}</ThankYouDescription>
              ) : matches.large ? (
                <Description>{parse(introText)}</Description>
              ) : null)}
          </ContentContainer>
        )}
        <ImageContainer>
          <Gradient simple={!!simple} />
          <Image
            background={matches.large ? heroImage : mobileHero || heroImage}
          />
        </ImageContainer>
      </HeaderContainer>
    );
  }
);

const HeaderBackground = styled.div`
  background: ${props => props.fill};
  ${props => props.marginBelow && `margin-bottom: 36px`}
`;

const HeaderImage = styled.img`
  display: block;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    height: auto;
    margin: 0 auto;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 24px;
`;

const Title = styled(H1)`
  color: ${props => (props.theme.dark ? '#FFFFFF' : '#222222')};
  margin-bottom: 8px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    margin-bottom: 4px;
    text-align: left;
    width: auto;
  }
`;

const BannerTitle = styled(H1)`
  color: ${props => props.color || (props.theme.dark ? '#FFFFFF' : '#222222')};
  margin: -50px 0 8px 10px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    margin: -50px 0 8px 10px;
    text-align: left;
    width: auto;
  }
`;

const Subitle = styled(Subheader)`
  color: ${props => (props.theme.dark ? '#FFFFFF' : '#222222')};
  margin-bottom: 40px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    color: #a6aeb2;
    margin-bottom: 24px;
    text-align: left;
    width: auto;
  }
`;

const HeaderContainer = styled(FlexBox)`
  flex-flow: row nowrap;
  height: 382px;

  @media only screen and ${props => props.theme.media.large} {
    border-bottom: 1px solid #a6acb0;
    height: auto;
  }
`;

const ContentContainer = styled(FlexBox)`
  align-items: center;
  box-sizing: border-box;
  color: ${props =>
    props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
  flex-flow: column nowrap;
  min-height: ${props => (props.simple ? '177px' : '382px')};
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  z-index: 5;

  @media only screen and ${props => props.theme.media.large} {
    align-items: flex-start;
    background-color: ${props => (props.theme.dark ? '#000000' : '#FFFFFF')};
    height: 100%;
    justify-content: flex-start;
    padding-top: 56px;
    padding-left: 5%;
    position: relative;
    width: 45%;
  }
`;

const ImageContainer = styled(FlexBox)`
  min-height: 100%;
  position: relative;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    width: 55%;
    flex-grow: 1;
  }
`;

const Gradient = styled.div`
  ${props =>
    props.theme.dark
      ? 'background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);'
      : 'background: linear-gradient(0deg, rgba(250, 250, 250, 1) 0%, rgba(250, 250, 250, 0) 100%);'};
  bottom: 0;
  height: ${props => (props.simple ? '16px' : '73%')};
  left: 0;
  position: absolute;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    ${props =>
      props.theme.dark
        ? 'background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);'
        : 'background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);'};
    bottom: inherit;
    height: 100%;
    top: 0;
    width: 104px;
  }
`;

const Image = styled.div`
  background-image: url(${props => props.background});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100%;
  width: 100%;
`;

const Description = styled(P)`
  margin-bottom: 26px;
  padding-right: 5%;
`;

const ThankYouDescription = styled(P)`
  margin-top: 45px;
  width: 100%;
  text-align: center;
  margin-bottom: 26px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const AltDescription = styled(P)`
  display: block;
  max-width: 1016px;
  padding-left: 5%;
  position: relative;
  margin: 20px;
`;
