import React from "react";
import styled from "styled-components";
import { Link as RouterLink, useParams } from "react-router-dom";
const LinkInternal = ({ to, ...props }) => {
  const { id } = useParams();
  return <RouterLink {...props} to={`/${to}`} />;
};

export const Link = styled(LinkInternal)`
  text-decoration: none;
  display: flex;
  & > {
    flex-grow: 1;
  }
`;
