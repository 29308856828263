import React, {
  createContext,
  useState,
  useEffect,
  useReducer,
  useMemo,
  useRef,
} from 'react';
import {useHistory} from 'react-router-dom';
import reducer from 'modules/reducer';
import {MAXTheme} from 'notes';
import _ from 'lodash';
import AlphaId from 'modules/AlphaId';
import languages from 'intl';
import {useCookies} from 'react-cookie';
import merge from 'merge';
import {QuestionTypes} from 'modules/constants';
import {useFirebaseContext} from 'hooks';
import {DateTime} from 'luxon';

export const SurveyContext = createContext();

export const SurveyConsumer = SurveyContext.Consumer;

const LANG_KEY = '___sts_locale';
const COOKIE_KEY = '__sts:instance';

const initialState = {
  locale: 'en',
  dimming: false,
  instance: {
    status: 0,
  },
  current_responses: {},
  current_page: 0,
  max_page: 0,
  collector: {
    setlist: {
      concerts: null,
      albums: [],
    },
  },
  formErrors: [],
};

const customTheme = {
  ...MAXTheme,
  dark: false,
  darkDefault: '#FFFFFF',
  lightDefault: '#222222',
  lightFooterBorder: '#D0D3D5',
  darkFooterBorder: '#535658',
  colors: {
    ...MAXTheme.colors,
    action: '#39A4A1',
    actionLight: '#EDF7F7',
    buttonPrimaryBgHover: '#105568',
    linkHover: '#105568',
    removeRed: '#E45C52',
    errorMessageBorder: '#EDB8B4',
  },
  media: {
    small: '(max-width: 375px)',
    medium: '(min-width: 376px) and (max-width: 1023px)',
    large: '(min-width: 1024px)',
  },
};

export const SurveyProvider = ({children, ...props}) => {
  const {callable} = useFirebaseContext();

  const history = useHistory();

  const [loading, setLoadingInternal] = useState(true);
  const [shows, setShows] = useState([]);
  const [songs, setSongs] = useState([]);
  const [step, setStep] = useState('setlist');
  const [state, setState] = useReducer(reducer, initialState);
  const [user, setUser] = useState();
  const [cookies, setCookie] = useCookies(['instance']);
  const [skipZip, setSkipZip] = useState(0);
  const [theme, setTheme] = useState(customTheme);
  const paymentHandler = useRef();

  const handleSurvey = async ({data}) => {
    //ignore non-sts message
    if (!data.stsLoad) {
      return;
    }
    const survey = _.get(data, 'survey');
    const collector = _.get(data, 'collector');

    let max_page = 0;
    let ref = null;

    const properties = _.get(survey, 'properties', {});

    let questionIndex = 1;

    const pages = _.get(survey, 'Page', []);

    pages.map(page => {
      // iterate through the section keys defined in the page (group)
      const sections = _.get(page, 'Section', []);

      sections
        .filter(section => !!section)
        .map(section => {
          // add a sequential number to each question (item)
          switch (section.id) {
            case 'spotify':
            case 'social':
              break;
            default:
              const questions = _.get(section, 'Question', []);
              questions.map(question => {
                if (
                  properties.numbered &&
                  question.status === 1 &&
                  question.numbered !== false
                ) {
                  question.number = questionIndex + '. ';
                  questionIndex++;
                }
                if (
                  !!question.validators &&
                  !Array.isArray(question.validators)
                ) {
                  question.validators = [question.validators];
                }
              });
              break;
          }
        });
    });

    const instanceId = AlphaId.generate();

    max_page =
      pages.length - (pages[pages.length - 1].id === 'thankyou' ? 2 : 1);
    const location = await callable('geo-locate', {instanceId}).then(
      ({
        data: {ip, city, country_code, region_code, zip, latitude, longitude},
      }) => {
        return {
          ip,
          city,
          country: country_code,
          region: region_code,
          zip,
          lat: latitude,
          lng: longitude,
        };
      }
    );
    const {ip, city, country, region, zip, lat, lng} = location;

    const instance = {
      id: instanceId,
      collectorId: collector.collector_id,
      survey_id: survey.id,
      ref: ref,
      // make sure this is 1 even though it's not used in the Lambda
      // or the survey will not load when React rerenders the page
      status: 1,
      ip,
      city,
      state: region,
      country,
      zip,
      lat,
      lng,
    };

    // save instance to firebase

    setState({
      ref,
      collector,
      survey,
      instance,
      answers: {},
      current_page: 0,
      max_page,
    });
    setLoading(false);
  };

  useEffect(() => {
    window.addEventListener('message', handleSurvey, false);
  });

  const setLoading = loading => {
    if (!loading) {
      const loader = document.querySelector('#loader');
      loader && loader.remove();
    }
    setLoadingInternal(loading);
  };

  const getLocation = async instanceId => {
    try {
      if (user && user.ip) return user;
      const result = await callable('geo-locate', {instanceId});
      const {ip, city, country_code, region_code, zip, latitude, longitude} =
        result.data || {};
      const location = {
        ip,
        city,
        country: country_code,
        region: region_code,
        zip,
        lat: latitude,
        lng: longitude,
      };
      setUser(location);
      return location;
    } catch (err) {
      console.log('Error getting location', err);
      return {};
    }
  };

  /**
   * Retrieve the specified collector and associated survey as well as
   * any previously supplied responses by the current user
   * @param {*} id
   */
  const getCollector = async (slug, instanceId) => {
    // Reconfigured so that we always have the GEO info because
    // it's needed for the Instance
    const location = await getLocation(instanceId);
    await setupCollector(slug, instanceId, location || {});
  };

  // separated out so that the Geo-locate is always run
  // first
  const setupCollector = async (slug, instanceId, location) => {
    //id = id.toLowerCase();

    // For STS we are not tracking a user instance with cookies like
    // the survey does (so users can resume), so each visitor (page load)
    // gets a new instance object.
    let answers = {};
    let instance;
    let collector;
    let survey;
    try {
      // get campaign collector
      const result = await callable('campaign-getCampaign', {
        id: slug.toLowerCase().replace(/\_DEV/, ''),
      });
      collector = result?.data;
      console.log('GOT COLLECTOR', collector);
      if (!instanceId) {
        instanceId = cookies[COOKIE_KEY];
      }
      if (instanceId) {
        // retrieve existing instance
        const {data} = await callable('instance-getInstance', {instanceId});
        instance = {
          id: data?.id,
        };
        Object.keys(data).forEach(key => {
          if (data[key]) instance[key] = data[key];
        });
        // now see if there are any previous answers recorded for this instance
        const results = await callable('instance-getResponses', {instanceId});
        if (results?.data?.length > 0) {
          results.data.forEach(result => {
            const {questionId, value} = result || {};
            if (questionId && value) {
              answers[questionId] = value;
            }
          });
        }
      }
      _.get(instance, 'Response', []).map(({element_id, strval}) => {
        answers[element_id] = strval;
      });
    } catch (err) {
      instance = {status: 0};
    }

    try {
      // ==========================================================
      // data      = previous answers (if user returning to survey)
      // collector = collector instance of survey
      // survey    = base survey object
      // ==========================================================
      const result = await callable('survey-getSurvey', {
        id: collector?.survey_id?.toLowerCase() || 'stsinfo',
      });
      survey = result?.data;
      // first custom pageview event
      window.dataLayer.push({
        event: 'sts_pageview',
        sts_campaign_id: collector.collector_id,
      });

      if (collector.fb_pixel && collector.fb_pixel !== '') {
        window.fbq('init', collector.fb_pixel);
        window.fbq('track', collector.fb_pixel, 'PageView');
      }

      // General FB Ad Pixel
      if (process.env.REACT_APP_ENV === 'prod') {
        window.fbq('track', '498999327432813', 'PageView');
      }

      let max_page = 0;
      let ref = null;

      if (survey) {
        const properties = _.get(survey, 'properties', {});

        let questionIndex = 1;

        const pages = _.get(survey, 'Page', []);

        pages.map(page => {
          // iterate through the section keys defined in the page (group)
          const sections = _.get(page, 'Section', []);

          sections
            .filter(section => !!section)
            .map(section => {
              // add a sequential number to each question (item)
              switch (section.id) {
                case 'spotify':
                case 'social':
                  break;
                default:
                  const questions = _.get(section, 'Question', []);
                  questions.map(question => {
                    if (
                      properties.numbered &&
                      question.status === 1 &&
                      question.numbered !== false
                    ) {
                      question.number = questionIndex + '. ';
                      questionIndex++;
                    }
                    if (
                      !!question.validators &&
                      !Array.isArray(question.validators)
                    ) {
                      question.validators = [question.validators];
                    }
                    // Pre-fill values
                    if (!answers.hasOwnProperty(question.id)) {
                      switch (parseInt(question.element_type, 10)) {
                        case 1:
                          answers[question.id] = question.checked;
                          break;
                      }
                    }
                  });
                  break;
              }
            });
        });

        max_page =
          pages.length - (pages[pages.length - 1].id === 'thankyou' ? 2 : 1);
      }

      // check instance/collector combo and see if we need to generate a new
      // id or if they have already completed this survey
      if (!instance || instance.collectorId !== collector.collector_id) {
        try {
          const urlParams = new URLSearchParams(window.location.search);
          ref = urlParams.get('ref');
        } catch (err) {}

        const {ip, city, country, region, zip, lat, lng} = location;

        instance = {
          id: AlphaId.generate(),
          collectorId: collector.collector_id,
          surveyId: survey.id,
          ref: ref,
          // make sure this is 1 even though it's not used in the Lambda
          // or the survey will not load when React rerenders the page
          status: 1,
          ip_address: ip,
          city,
          state: region,
          country,
          zip,
          lat,
          lng,
          createdAt: DateTime.utc().toMillis(),
          updatedAt: DateTime.utc().toMillis(),
          processedAt: null,
          userAgent: window.navigator.userAgent,
          language: state?.locale,
        };
        // record survey instance
        await callable('instance-updateInstance', {id: instance.id, instance});
        setCookie(COOKIE_KEY, instance.id);
      }

      const theme = merge.recursive(
        true,
        customTheme,
        _.get(collector, 'theme', {})
      );

      setTheme(theme);

      // make sure there is a default theme entry
      // in the collector (used by Header)
      if (!collector.theme) {
        collector.theme = {};
      }

      setState({
        ref,
        collector,
        survey,
        instance,
        answers,
        current_page: 0,
        max_page,
      });

      setLoading(false);

      if (instance?.status === 10) {
        history.push('/survey-complete');
        return;
      }
    } catch (err) {
      setState({instance: {status: -1}});
      console.error(err);
    }
  };

  /**
   * Wrapper to send survey response so we don't have to call
   * the API individually throughout the app
   */
  const saveResponses = async answers => {
    const {collector, instance} = state;

    // filter out incomplete answers that could result in
    // an event or class (need to fix!)
    try {
      await callable('instance-saveResponses', {
        collectorId: collector.collector_id,
        instanceId: instance.id,
        responses: answers.filter(
          answer =>
            answer.value &&
            (Array.isArray(answer.value) ||
              ['string', 'boolean', 'number'].includes(typeof answer.value))
        ),
      });
    } catch (err) {
      return {errors: [err.message]};
    }

    return {success: true};
  };

  /**
   * Submit answers to data endpoint
   * Assumes validation done by the calling function
   */
  const submitSurveyAnswers = async () => {
    const {current_responses, collector, instance} = state;

    if (paymentHandler.current) {
      const paymentResult = await paymentHandler.current();
      if (paymentResult && paymentResult.error) {
        return {errors: ['Payment Unsuccessful']};
      }
    }

    //const responses = currentResponses();

    const answers = Object.keys(current_responses).map(key => {
      return {
        questionId: key,
        value:
          key === 'country'
            ? current_responses[key] === ''
              ? null
              : current_responses[key].value
            : current_responses[key] === ''
            ? null
            : current_responses[key],
      };
    });

    // HACK - make sure any questions not appearing in the
    // answers list that have a default value are added
    try {
      const page = getCurrentPage();
      _.get(page, 'Section', [])
        .filter(({status}) => status === 1)
        .map(section => {
          const question = _.get(section, 'Question', []);
          question
            .filter(({status}) => status === 1)
            .map(question => {
              let row = _.find(
                answers,
                ({questionId}) => questionId === question.id
              );
              switch (parseInt(question.element_type, 10)) {
                case QuestionTypes.CHECKBOX:
                  if (!row && question.checked === true) {
                    answers.push({
                      questionId: question.id,
                      value: question.checked,
                    });
                  }
                  break;
              }
            });
        });
    } catch (err) {
      console.log(err);
    }

    return saveResponses(answers);
  };

  /**
   * Return the current survey page
   */
  const getCurrentPage = () => {
    const pages = _.get(state, 'survey.Page', []);
    const current_page = _.get(state, 'current_page', 0);
    return pages && pages.length ? pages[current_page] : null;
  };

  /**
   * Markes a survey instance as completed
   */
  const updateInstance = async () => {
    const {instance} = state;
    await callable('instance-completeInstance', {id: instance.id});
    setState({instance: {...instance, status: 10}});
  };

  /**
   * Tracks current page so that responses for each page
   * can be tracked
   * @param {*} key
   */
  const setCurrentPage = index => {
    setState({current_page: index, current_responses: {}, formErrors: []});
  };

  /**
   * returns responses for current page (group)
   */
  const currentResponses = () => {
    return _.cloneDeep(_.get(state, 'current_responses', {}));
  };

  const onResponse = (id, value, key) => {
    let current_responses = _.cloneDeep(_.get(state, 'current_responses', {}));
    if (typeof value === 'string') value = value.trim();
    if (typeof key === 'string') key = key.trim();
    // null values will get deleted when the survey is saved
    current_responses[id] = value;
    setState({current_responses, formErrors: clearError(key || id)});
  };

  /**
   * Return ALL responses
   */
  const responses = () => {
    const result = state
      ? _.extend(
          {},
          _.cloneDeep(_.get(state, 'answers', {})),
          _.cloneDeep(_.get(state, 'current_responses', {}))
        )
      : {};
    return result;
  };

  /**
   * Recall a specific data element
   */
  const getResponse = id => {
    return _.get(responses(), id, null);
  };

  /**
   * Clear single form error object
   */
  const clearError = key => {
    let errors = _.cloneDeep(_.get(state, 'formErrors', []));
    const index = errors.indexOf(key);
    if (index > -1) {
      errors.splice(index, 1);
    }
    return errors;
  };

  /**
   * Clear all global form errors
   */
  const clearErrors = () => {
    setState({formErrors: []});
  };

  const flatSongs = useMemo(() => {
    const {collector} = state;
    return collector?.setlist?.albums.reduce((accum, album) => {
      const albumSongs = album.songs.map(song => ({
        ...song,
        album,
      }));
      return accum.concat(albumSongs);
    }, []);
  }, [state]);

  const getCustomization = key => {
    const {
      locale,
      collector: {
        setlist: {customizations},
      },
    } = state;
    try {
      let result = _.get(customizations, `${locale}.${key}`);
      if (!result) {
        result = _.get(customizations, `en.${key}`);
      }
      if (!result) {
        result = _.get(customizations, key);
      }
      return result;
    } catch (err) {
      return key;
    }
  };

  const getLanguage = () => {
    // check localstorage first
    let lang = window.localStorage.getItem(LANG_KEY);
    if (!lang || lang === '') {
      lang = (navigator.language || 'en').toLowerCase();
      if (lang.indexOf('-') > 0) {
        lang = lang.split('-')[0];
      }
      if (Object.keys(languages).indexOf(lang) < 0) {
        lang = 'en';
      }
      window.localStorage.setItem(LANG_KEY, lang);
    }
    return lang;
  };

  const setLanguage = lang => {
    window.localStorage.setItem(LANG_KEY, lang);
    setState({locale: lang || 'en'});
  };

  // simple way to track and export functions
  const functions = {
    getCollector,
    setCurrentPage,
    getCurrentPage,
    currentResponses,
    responses,
    onResponse,
    submitSurveyAnswers,
    saveResponses,
    updateInstance,
    getLanguage,
    setLanguage,
    getCustomization,
    getResponse,
  };

  const value = {
    state,
    loading,
    setLoading,
    flatSongs,
    setState,
    shows,
    setShows,
    songs,
    setSongs,
    step,
    setStep,
    skipZip,
    setSkipZip,
    paymentHandler,
    theme,
    ...functions,
  };

  useEffect(() => {
    //TODO: actual API access
    const lang = getLanguage();
    setState({loading: true, locale: lang});
    const {collector} = state;
    let slug;
    try {
      slug = window.location.hostname.split('.')[0];
    } catch (err) {}
    if (!!slug) {
      switch (slug.toLowerCase()) {
        case 'auth':
          setLoading(false);
          break;
        default:
          if (!collector || (collector && collector.id !== slug)) {
            getCollector(slug);
          }
          break;
      }
    } else {
      window.location = 'https://www.settheset.com/';
    }
  }, []);

  return (
    <SurveyContext.Provider value={value} {...props}>
      {children}
    </SurveyContext.Provider>
  );
};
