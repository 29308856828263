import React, { useContext } from "react";
import { SurveyContext } from "Providers";

const SpotifyAuth = () => {
  const { setLoading } = useContext(SurveyContext);

  setLoading(false);

  try {
    const hash = window.location.hash.substr(1);

    const data = hash.split("&").reduce((result, item) => {
      const parts = item.split("=");
      result[parts[0]] = parts[1];
      return result;
    }, {});

    if (data && !!data.access_token) {
      window.opener.postMessage(
        JSON.stringify({ token: data.access_token }),
        "*"
      );
    }
    window.close();
  } catch (err) {
    console.log(err);
    window.close();
  }

  return <div />;
};

export default SpotifyAuth;
