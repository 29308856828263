import React, {Fragment} from 'react';
import styled from 'styled-components';
import {ShowSelector, H4} from 'notes';
import {SurveyConsumer} from 'Providers';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';

export const ConnectedShowSelector = props => {
  const history = useHistory();
  const handleNext = () => {
    history.push('songs');
  };
  return (
    <SurveyConsumer>
      {({
        state: {
          collector: {
            setlist: {concerts},
          },
        },
        getCustomization,
        shows,
        setShows,
      }) =>
        concerts &&
        concerts.length > 0 && (
          <Fragment>
            <Title>
            {getCustomization('showSelectorLabel') || 
                <FormattedMessage
                  id="prompt.which_concert"
                  defaultMessage="Which of our concerts are you planning to attend?"
                />
              }
            </Title>
            <ShowSelector
              concerts={concerts}
              value={shows}
              setValue={setShows}
              onNext={handleNext}
              {...props}
            />
          </Fragment>
        )
      }
    </SurveyConsumer>
  );
};

const Title = styled(H4)`
  color: ${props =>
    props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
  margin-bottom: 16px;
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => props.theme.lightDefault};
  }
`;
