import React from "react";
import styled from "styled-components";
import { FlexBox, P } from "notes";
const Container = styled(FlexBox)``;
const Image = styled.img`
  width: 32px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.white};
`;
const ImageContainer = styled.div`
  width: 18px;
  height: 36px;
  &:last-child {
    width: 36px;
  }
`;
const BlankAlbum = styled(FlexBox)`
  width: 32px;
  height: 32px;
  border: 2px solid ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.actionLight};
  color: ${props => props.theme.colors.action};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
const BlankAlbumText = styled(P)`
  text-decoration: none;
`;
export const AlbumDisplay = ({ albums }) => (
  <Container>
    {albums.length > 3 ? albums.slice(0, 2).map(Album) : albums.map(Album)}
    {albums.length > 3 && (
      <BlankAlbum>
        <BlankAlbumText>+{albums.length - 2}</BlankAlbumText>
      </BlankAlbum>
    )}
  </Container>
);

const Album = ({ image, images }) => (
  <ImageContainer>
    <Image src={!!image ? image : images[0].url} />
  </ImageContainer>
);
