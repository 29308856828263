import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import countries from "modules/countries";
import { SurveyContext } from "Providers";
import { Select } from "notes";
import { useIntl } from "react-intl";

const SelectList = ({ data = null, error = false }) => {
  const {
    onResponse,
    responses,
    state: { user, locale }
  } = useContext(SurveyContext);

  const { formatMessage } = useIntl();

  const [selected, setSelected] = useState(_.get(responses(), data.id, null));

  useEffect(() => {
    onResponse(data.id, !!selected ? selected.text : null);
  }, [selected]);

  useEffect(() => {
    const value = _.get(responses(), data.id, null);
    if (value && data) {
      handleChange({ id: value, value: value, text: value });
    } else {
      const source = _.get(data, "source", null);
      switch (source) {
        case "country":
          if (user) {
            const country = _.get(user, "country", null);
            if (country) {
              handleChange({
                id: country,
                value: country,
                text: country.toLowerCase()
              });
            }
          }
          break;
        default:
          break;
      }
    }
  }, [user, data]);

  const options = () => {
    const source = _.get(data, "source", null);
    if (!!source) {
      switch (source) {
        case "country":
          return Object.keys(countries).map(key => {
            const { name } = countries[key];
            return {
              id: `_country_${name.replace(/\s/g, "_")}_${key}`,
              value: key.toLowerCase(),
              text: name
            };
          });
        default:
          break;
      }
    } else {
      return _.get(data, `translations.${locale}.Option`, []).map(
        (answer, index) => {
          return {
            id: `answer_${data.id}_${index}`,
            value: answer.value,
            text: answer.name
          };
        }
      );
    }
  };

  const handleChange = ({ id, value, text }) => {
    setSelected({ id, value, text });
  };

  const render = () => {
    let props = {};
    if (!!selected) {
      props.selected = selected;
    }

    const placeholder = _.get(
      data,
      `translations.${locale}.placeholder`,
      formatMessage({
        id: "label.select",
        defaultMessage: "Please Select"
      })
    );

    return (
      <div style={{ position: "relative" }}>
        <Select
          selection
          placeholder={placeholder}
          options={options()}
          onChange={handleChange}
          data-javelin-name={`input-select`}
          data-javelin-meta={data && data.id}
          {...props}
        />
        {/* {error && (
          <i name="warning sign" color="red" style={{ marginLeft: "1rem" }} />
        )} */}
      </div>
    );
  };

  return render();
};

export default SelectList;
