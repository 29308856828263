import React, { Component } from "react";
import _ from "lodash";
import styled from "styled-components";
import { ButtonSpotify, H4, ButtonFacebook, GridColumn } from "notes";
import { SurveyContext } from "Providers";
import Api from "modules/Api";
import { Tickets } from "./Tickets";
import { FormattedMessage, useIntl, injectIntl } from "react-intl";

class Spotify extends Component {
  static contextType = SurveyContext;

  state = { spotifyTracks: [] };

  messageHandler = e => {
    const {
      state: {
        instance,
        collector: { id, collector }
      },
      saveResponses
    } = this.context;
    if (!!e.data && typeof e.data === "string") {
      try {
        const { token } = JSON.parse(e.data);
        if (token && token !== "") {
          // save share event to responses db for reporting
          saveResponses([
            {
              questionId: "spotify_auth",
              value: 1
            },
            {
              questionId: "spotify_token",
              value: token
            }
          ]);
          this.createSpotifyPlaylist(token);
        }
      } catch (err) {}
    }
  };

  componentDidMount = () => {
    window.addEventListener("message", this.messageHandler);
    // filter out any songs that do not have a spotify id
    this.getSpotifyTracks();
  };

  componentWillUnmount = () => {
    window.removeEventListener("message", this.messageHandler);
  };

  getSpotifyTracks = () => {
    const {
      state: {
        collector: {
          setlist: {
            albums,
            customizations: { autoAddTracks }
          }
        }
      },
      songs
    } = this.context;

    // Get the song selections (may have to change to store spotify URI with them)
    // and add to playlist
    const allSongs = albums.reduce((accum, album) => {
      const albumSongs = album.songs
        .filter(song => !!song.spotify_id && song.spotify_id !== "")
        .map(song => ({
          ...song
        }));
      return accum.concat(albumSongs);
    }, []);

    const tracks = allSongs
      .filter(({ id }) => songs.includes(id))
      .sort(
        ({ id: idA }, { id: idB }) => songs.indexOf(idA) - songs.indexOf(idB)
      )
      .map(({ uri, spotify_id }) =>
        !!uri ? uri : `spotify:track:${spotify_id}`
      );

    // generate list of spotify uri's from song selections
    const combinedTracks = autoAddTracks
      ? autoAddTracks
          .map(track =>
            track.indexOf("spotify:track") >= 0
              ? track
              : `spotify:track:${track}`
          )
          .concat(tracks.filter(item => autoAddTracks.indexOf(item) < 0))
      : tracks;

    this.setState({ spotifyTracks: combinedTracks });
    return combinedTracks;
  };

  shareFB = () => {
    const { formatMessage } = this.props.intl;
    const {
      state: {
        instance,
        collector: { id, artist, collector }
      },
      saveResponses
    } = this.context;
    const quote = formatMessage(
      {
        id: "spotify.share.text",
        defaultMessage: `I just created the perfect setlist for ${artist.name} on Set The Set!`
      },
      {
        artist: artist.name
      }
    );
    // Use current host name + share
    window.FB.ui({
      method: "share",
      href: `${window.location.origin}/share/${instance.id}`,
      quote: quote,
      hashtag: "#settheset"
    });
    // save share event to responses db for reporting
    saveResponses([{ questionId: "fb_share", value: 1 }]);
  };

  // Called by child window to pass auth token
  createSpotifyPlaylist = token => {
    window.removeEventListener("message", this.messageHandler);
    const {
      state: {
        instance,
        collector: {
          artist,
          setlist: {
            albums,
            customizations: { autoAddTracks }
          }
        }
      },
      songs,
      saveResponses
    } = this.context;

    const { spotifyTracks } = this.state;

    if (!!token && spotifyTracks.length > 0) {
      const submission = {
        i: instance.id,
        c: instance.collectorId,
        songs
      };

      Api.getSpotifyUser(token)
        .then(result => {
          if (result) {
            const { id } = result;
            return Api.createPlaylist(token, id, `${artist.name} Playlist`);
          }
          throw Error("Could not get user token");
        })
        .then(playlist => {
          if (playlist) {
            // save share event to responses db for reporting
            saveResponses([{ questionId: "playlist", value: playlist.id }]);
            Api.addTracks(token, playlist.id, spotifyTracks).then(res => {
              // redirect to user's playlist on spotify
              window.location = playlist.external_urls.spotify;
            });
          } else {
            throw Error("Could not create playlist");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  spotifyAuth = f => {
    const child = window.open(
      `https://accounts.spotify.com/authorize?client_id=${process.env.REACT_APP_SPOTIFY_CLIENT_ID}&scope=user-read-private,ugc-image-upload,playlist-modify-public&redirect_uri=${process.env.REACT_APP_SPOTIFY_CALLBACK}&response_type=token`,
      "Set the Set Spotify Authorization",
      // left=(calculate from screen width),top=(calculate from screen height), height=(calculate from screen height), width=??
      "width=400,height=600"
    );
  };

  render() {
    const { getCustomization } = this.context;
    let spotifyLabel = getCustomization("spotifyPlaylistMessage");
    let hideSocialShare = getCustomization("hideSocialShare");
    const { spotifyTracks } = this.state;
    return (
      <SpotifyContainer>
        {spotifyTracks.length > 0 && (
          <MakeSetListText>
            {spotifyLabel ? (
              spotifyLabel
            ) : (
              <FormattedMessage
                id="spotify.playlist"
                default="Don’t forget to turn these songs into a custom Spotify playlist!"
              />
            )}
          </MakeSetListText>
        )}
        {spotifyTracks.length > 0 && (
          <SpotifyLink>
            <SpotifyButton
              onClick={() => this.spotifyAuth()}
              data-javelin-name={`spotify-export`}
            >
              <FormattedMessage
                id="spotify.listen"
                default="Listen on Spotify"
              />
            </SpotifyButton>
          </SpotifyLink>
        )}
        {!hideSocialShare && (
          <FacebookContainer>
            <ButtonFacebook
              onClick={() => this.shareFB()}
              data-javelin-name={`facebook-share`}
            >
              <FormattedMessage
                id="spotify.share"
                default="Share my Playlist"
              />
            </ButtonFacebook>
          </FacebookContainer>
        )}
        <Tickets />
      </SpotifyContainer>
    );
  }
}

export default injectIntl(Spotify);

const SpotifyContainer = styled.div`
  text-align: center;
`;

const MakeSetListText = styled(H4)`
  text-align: center;
`;

const FacebookContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;

const SpotifyButton = styled(ButtonSpotify)`
  width: 100%;
  margin: 0;
  @media only screen and ${props => props.theme.media.large} {
    min-width: 392px;
    height: 40px;
    width: auto;
  }
`;

const SpotifyLink = styled.a`
  display: inline-block;
  margin: 24px auto 0;
  text-decoration: none;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    width: auto;
  }
`;
