import React from "react";
import { withTheme } from "styled-components";
import { Link } from "react-router-dom";
import { withDefaultMedia } from "notes";
import { ReactComponent as DarkLogoSmall } from "Images/DarkLogoSmall.svg";
import { ReactComponent as DarkLogoLarge } from "Images/DarkLogoLarge.svg";
import { ReactComponent as LightLogoSmall } from "Images/LightLogoSmall.svg";
import { ReactComponent as LightLogoLarge } from "Images/LightLogoLarge.svg";

const LogoInternal = withTheme(({ matches, theme, footer = false }) => {
  if (footer) {
    return (
      <Link to="/">
        {matches.large ? (
          <LightLogoSmall />
        ) : theme.dark ? (
          <DarkLogoSmall />
        ) : (
          <LightLogoSmall />
        )}
      </Link>
    );
  }
  return theme.dark ? <DarkLogoLarge /> : <LightLogoLarge />;
});

export const Logo = withDefaultMedia(LogoInternal);
