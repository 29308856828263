export const QuestionTypes = {
  CHECKBOX: 1,
  TEXT: 2,
  SELECT: 3,
  SELECTLIST: 4,
  AUTOCOMPLETE: 6,
  RADIO: 7,
  RANGE: 9,
  PAYMENT: 10,
  TEXTAREA: 11
};

export const demo_data = {
  success: 1,
  data: {
    extra_parameters: {
      includes: "collector,survey",
      lang: "en"
    },
    data: {},
    collector: {
      name: "The Farrow",
      survey_id: "BviMfV2",
      type: 0,
      colors: {
        user_colors: {
          background: "#7c60d9",
          button: "#7c60d9"
        }
      },
      css:
        "html, body {background-color: #f6f9fa;} body{padding:30px;}.controls .button {background-color: #4EAAA6 !important; color:  !important;}\n                .controls .button:hover {background-color:  !important;}",
      css_file: "/css/survey-GME.min.css",
      custom_thankyou_html:
        '<style>\n.hidden-us-me {\n    display:none;\n}\n.hidden-me-us {\n    display:none;\n}\n</style>\n<div class="form">\n    <h1 style="text-align: center;padding: 20px 5px;">\n        <span class="translate">Thanks for helping out</span> Music Audience Exchange!\n    </h1>\n    <h2 style="color: black; padding: 0 20px;text-align: center;">\n        <span class="translate hidden-us-us">If there\'s anything you want to add or say let us know here</span>\n        <span class="translate hidden-me-us">If there\'s anything you want to add or say let me know here</span>\n    !</h2>\n    <form style="width: 100%; max-width: 500px; margin: 40px auto; padding: 10px;">\n        <textarea name="post_survey_input" style="width: 100%; font-size: 12px; height: 100px; padding: 10px;">\n        </textarea>\n        <div class="controls">\n        <button type="submit" class="button large" style="width: 100%;">\n            <span class="translate">Send</span>\n        </button>\n        </div>\n    </form>\n</div>\n<div style="display:none;">\n    <span id="thank-you" class="translate">Thank You</span>\n</div>\n\n<script>\n$(\'form\').on(\'submit\', function(e){\n        e.preventDefault();\n        var thankYou = $("#thank-you").text();\n        $.ajax({\n                type: "POST",\n                url: "/papi/v0/survey/<%= surveyId %>/<%= instanceId %>",\n                data: JSON.stringify({ "post_survey_input": $(\'textarea\').val() }),\n                contentType: \'application/json\'\n        })\n            .done(function(data, status, xhr) {\n                $(\'form\').html(\'<h3 style="text-align: center;">\'+thankYou+\'</h3>\');\n            })\n            .fail(function(data, status, xhr) {\n                $(\'form\').html(\'<h3 style="text-align: center;">\'+thankYou+\'</h3>\');});\n            });\n</script>',
      asset_id: "banner_url",
      properties: {
        fillable: [
          {
            name: "intro_text",
            title: "Intro Text",
            value:
              "This survey will help us find brand sponsors for your favorite artists. It should only take about 7-8 minutes to complete.\n                        "
          },
          {
            name: "intro_text_es",
            title: "Intro Text Spanish",
            value:
              "Esta encuesta nos ayudará a encontrar compañías que puedan hacer que la música de Music Audience Exchange sea escuchada en la radio y servicios como Pandora. Sólo te tomará unos 7-8 minutos en completarla.\n                        "
          },
          {
            name: "artist_name",
            title: "Artist Name",
            value: "Music Audience Exchange"
          },
          {
            name: "artist_plural",
            title: "Band or Individual",
            default: "our",
            value: ""
          },
          {
            name: "band_name",
            title: "Band Name",
            value: ""
          },
          {
            name: "prize_text",
            title: "Prize Text",
            default: "a prize",
            value: ""
          },
          {
            name: "gender",
            type: "gender",
            title: "Gender",
            value: ""
          },
          {
            name: "background_color",
            type: "color",
            title: "Survey Background Color",
            default: "white",
            value: ""
          },
          {
            name: "prev_button_color",
            type: "color",
            title: "Prev Button Color",
            value: ""
          },
          {
            name: "next_button_color",
            type: "color",
            title: "Next Button Color",
            value: ""
          },
          {
            name: "background_image_url",
            title: "Background Image URL",
            default: "/public/img/demo_background.png",
            value: ""
          },
          {
            name: "banner_url",
            title: "Banner Image URL",
            default: "/img/demo_background.png",
            value: "/img/demo_background.png"
          },
          {
            name: "gender_pronoun",
            title: "Gender Pronoun",
            default: "our",
            value: ""
          },
          {
            name: "text_color",
            title: "Text Color",
            default: "black",
            value: ""
          },
          {
            name: "us_me",
            default: "us",
            value: "us"
          },
          {
            name: "our_my",
            default: "our",
            value: ""
          },
          {
            name: "tab_color_hover",
            value: ""
          },
          {
            value: ""
          },
          {
            name: "we",
            value: ""
          },
          {
            name: "he_she",
            value: ""
          },
          {
            name: "profile_img",
            value: ""
          }
        ]
      }
    },
    survey: {
      name: "FALLOWS",
      properties: {
        numbered: true,
        pageoftotaltitle: true,
        env: "development",
        data: {
          fillable: [
            {
              name: "intro_text",
              title: "Intro Text"
            },
            {
              name: "intro_text_es",
              title: "Intro Text Spanish"
            },
            {
              name: "artist_name",
              title: "Artist Name"
            },
            {
              name: "artist_plural",
              title: "Band or Individual",
              default: "our"
            },
            {
              name: "band_name",
              title: "Band Name"
            },
            {
              name: "prize_text",
              title: "Prize Text",
              default: "a prize"
            },
            {
              name: "gender",
              type: "gender",
              title: "Gender"
            },
            {
              name: "background_color",
              type: "color",
              title: "Survey Background Color",
              default: "white"
            },
            {
              name: "prev_button_color",
              type: "color",
              title: "Prev Button Color"
            },
            {
              name: "next_button_color",
              type: "color",
              title: "Next Button Color"
            },
            {
              name: "background_image_url",
              title: "Background Image URL",
              default:
                "http://afromatrix.net/wp-content/uploads/2016/01/music-banner-1.jpg"
            },
            {
              name: "banner_url",
              title: "Banner Image URL",
              default: "/img/demo_background.png"
            },
            {
              name: "gender_pronoun",
              title: "Gender Pronoun",
              default: "our"
            },
            {
              name: "text_color",
              title: "Text Color",
              default: "black"
            },
            {
              name: "us_me",
              default: "us"
            },
            {
              name: "our_my",
              default: "our"
            },
            {
              name: "tab_color_hover"
            },
            {
              name: "checkbox_color"
            },
            {
              name: "we"
            },
            {
              name: "he_she"
            },
            {
              name: "profile_img"
            }
          ],
          progress_bar: false
        }
      },
      instance: {
        id: "7TNruIe"
      },
      id: "BviMfV2",
      groups: {
        JJTlJV0: {
          name: "intro",
          title: "Hey Guys!",
          intro:
            "I'm super stoked to get back on the road in 2020! I worked my ass off this year to get new music finished and released to all or you...now it's time for some fun.  TOUR WAY!  Let me know which cities I absolutely have to come thru on this next tour.  Can't wait to see you guys in person. ONLY US!",
          position: 0,
          status: 1,
          properties: {
            data: null
          },
          custom_css_classes: "",
          sections: ["qCxLhB9"]
        },
        thankyou: {
          name: "thankyou",
          title: "Thank you so much for the support!",
          intro:
            "If you make it out to a show, make sure to come say what up to me! Looking forward to making some crazy memories in your city. ONLY US!!",
          sections: ["spotify", "social"]
        }
      },
      sections: {
        qCxLhB9: {
          title: "",
          intro: "",
          name: "main",
          asset_id: "",
          properties: {
            data: null,
            position: 0,
            section_type: 1,
            columns: 1
          },
          random: 0,
          group: "JJTlJV0",
          status: 1,
          custom_css_classes: "",
          required: 1,
          items: [
            {
              id: "Qbrg9RH_3",
              status: 1,
              survey_section_id: "qCxLhB9",
              required: 1,
              element_type: 2,
              title: "What's your name?",
              tags: "",
              validators: "",
              name: "",
              placeholder: "First and last name preferred...",
              custom_css_classes: "",
              default_val: "",
              position: 0,
              matrix_head: null,
              matrix_row: null,
              options: []
            },
            {
              id: "Qbrg9RH_4",
              status: 1,
              survey_section_id: "qCxLhB9",
              required: 1,
              element_type: 4,
              title: "Are you:",
              tags: "",
              validators: "",
              name: "",
              placeholder: "Please select a gender...",
              custom_css_classes: "",
              default_val: "",
              position: 0,
              matrix_head: null,
              matrix_row: null,
              options: [
                {
                  name: "Female",
                  type: 1,
                  value: "Female"
                },
                {
                  name: "Male",
                  type: 1,
                  value: "Male"
                },
                {
                  name: "Non-Binary/Other",
                  type: 1,
                  value: "Non-Binary/Other"
                },
                {
                  name: "Prefer Not to Say",
                  type: 1,
                  value: "Prefer Not to Say"
                }
              ]
            },
            {
              id: "Qbrg9RH_5",
              status: 1,
              survey_section_id: "qCxLhB9",
              required: 1,
              element_type: 9,
              range_start: -100,
              range_end: -5,
              title: "What year were you born?",
              tags: "",
              validators: "",
              name: "",
              placeholder: "Please select a year...",
              custom_css_classes: "",
              default_val: "",
              position: 0,
              matrix_head: null,
              matrix_row: null,
              options: []
            },
            {
              id: "Qbrg9RH_6",
              status: 1,
              survey_section_id: "qCxLhB9",
              required: 1,
              element_type: 4,
              source: "country",
              title: "In what country do you currently reside?",
              tags: "",
              validators: "country",
              name: "",
              placeholder: "",
              custom_css_classes: "",
              default_val: "",
              position: 0,
              matrix_head: null,
              matrix_row: null,
              options: []
            },
            {
              id: "Qbrg9RH_7",
              status: 1,
              survey_section_id: "qCxLhB9",
              required: 1,
              element_type: 2,
              source: "country",
              title: "What is your residential ZIP / Postal Code?",
              tags: "",
              validators: ["zip"],
              name: "",
              placeholder: "5-digit postal code...",
              custom_css_classes: "",
              default_val: "",
              position: 0,
              matrix_head: null,
              matrix_row: null,
              options: []
            },
            {
              id: "Qbrg9RH_8",
              status: 1,
              survey_section_id: "qCxLhB9",
              required: 1,
              element_type: 2,
              source: "country",
              title: "What's your email address?",
              tags: "",
              validators: ["email"],
              name: "",
              placeholder: "john@email.com",
              custom_css_classes: "",
              default_val: "",
              position: 0,
              matrix_head: null,
              matrix_row: null,
              options: []
            },
            {
              id: "Qbrg9RH_9",
              status: 1,
              survey_section_id: "qCxLhB9",
              required: 0,
              element_type: 2,
              source: "country",
              title:
                "Get texts from me or the ONLY US crew by giving us your phone number!",
              tags: "",
              validators: ["phone", "country"],
              name: "",
              placeholder: "",
              custom_css_classes: "",
              default_val: "",
              position: 0,
              matrix_head: null,
              matrix_row: null,
              options: [
                {
                  type: "popover",
                  header: "Why we're asking for your number...",
                  label: "What kinds of texts or things will you send me?",
                  content:
                    "I/we may periodically text you about things such as upcoming releases, concert/tour dates and general information that you may find useful or interesting.  We promise to never sell your number or spam you with messages.  Also, keep in mind that texting rates may apply."
                }
              ]
            }
          ]
        },
        spotify: {
          uri: "https://open.spotify.com/artist/5Iz4416TJcu3U3s8rPzifd"
        }
      },
      assets: {}
    },
    extra_translations: {
      "Select an option...": "Seleccione una opcion...",
      Next: "Siguiente",
      Previous: "Anterior",
      "Please answer all the questions above":
        "Por favor, conteste todas las preguntas anteriores",
      "Please select at least": "Por favor, seleccione al menos",
      answers: "respuestas",
      "Please select up to": "Por favor, seleccione hasta",
      "Thank You": "Gracias",
      "If there's anything you want to add or say let me know here":
        "Si hay algo que desees hacerme saber o decir, hazlo aquí",
      "If there's anything you want to add or say let us know here":
        "Si hay algo que desees agregar o decirnos, hazlo aquí:",
      "Thanks for helping out": "Gracias por tu ayuda",
      Send: "Enviar",
      finish: "terminar"
    },
    system: {
      timestamp: 1566923763767,
      v: 105.1,
      version: {
        date: "2019-08-27 04:36:03",
        hash: "5825798e49399"
      },
      notifications: []
    },
    user: {
      id: 0,
      display_name: ""
    }
  }
};

export const autoCompleteOptions = {
  venue: {
    status: {
      rid: "lc+N+M0thwEK4rdh",
      "time-ms": 1
    },
    hits: {
      found: 137,
      start: 0,
      hit: [
        {
          id: "457772",
          fields: {
            city: "Los Angeles",
            display_name: "Los Angeles County Store, Los Angeles, CA",
            event_count: "1",
            search_name: "Los Angeles County Store",
            subtype: "venue",
            state: "CA",
            coords: "34.0522,-118.243",
            type: "venue"
          }
        },
        {
          id: "413473",
          fields: {
            city: "Los Angeles",
            display_name: "IE Los Angeles Office, Los Angeles, CA",
            event_count: "2",
            search_name: "IE Los Angeles Office",
            subtype: "venue",
            state: "CA",
            coords: "34.0878793,-118.3446705",
            type: "venue"
          }
        },
        {
          id: "537597",
          fields: {
            city: "Los Angeles",
            display_name: "The Studio Los Angeles, Los Angeles, CA",
            event_count: "1",
            search_name: "The Studio Los Angeles",
            subtype: "venue",
            state: "CA",
            coords: "34.1596532,-118.5018127",
            type: "venue"
          }
        },
        {
          id: "476611",
          fields: {
            city: "Los Angeles",
            display_name: "Los Angeles Writers Group, Los Angeles, CA",
            event_count: "4",
            search_name: "Los Angeles Writers Group",
            subtype: "venue",
            state: "CA",
            coords: "34.107,-118.372",
            type: "venue"
          }
        },
        {
          id: "511563",
          fields: {
            city: "Los Angeles",
            display_name:
              "University of California, Los Angeles, Los Angeles, CA",
            event_count: "1",
            search_name: "University of California, Los Angeles",
            subtype: "venue",
            state: "CA",
            coords: "34.0719125,-118.4505357",
            type: "venue"
          }
        },
        {
          id: "357215",
          fields: {
            city: "Los Angeles",
            display_name:
              "LACE (Los Angeles Contemporary Exhibitions), Los Angeles, CA",
            event_count: "1",
            search_name: "LACE (Los Angeles Contemporary Exhibitions)",
            subtype: "venue",
            state: "CA",
            coords: "34.0998,-118.328",
            type: "venue"
          }
        },
        {
          id: "931",
          fields: {
            city: "Los Angeles",
            display_name:
              "University of California, Los Angeles (UCLA), Los Angeles, CA",
            event_count: "798",
            search_name: "University of California, Los Angeles (UCLA)",
            subtype: "venue",
            state: "CA",
            coords: "34.068921,-118.4451811",
            type: "venue"
          }
        },
        {
          id: "553435",
          fields: {
            city: "Studio City",
            display_name: "Los Angeles, Studio City, CA",
            event_count: "1",
            search_name: "Los Angeles",
            subtype: "venue",
            state: "CA",
            coords: "34.1486,-118.396",
            type: "venue"
          }
        },
        {
          id: "547391",
          fields: {
            city: "Wilmington",
            display_name: "Los Angeles Harbor College, Wilmington, CA",
            event_count: "1",
            search_name: "Los Angeles Harbor College",
            subtype: "venue",
            state: "CA",
            coords: "33.78,-118.262",
            type: "venue"
          }
        },
        {
          id: "363898",
          fields: {
            city: "Pasadena",
            display_name:
              "[Pasadena] International School of Los Angeles, Pasadena, CA",
            event_count: "13",
            search_name: "[Pasadena] International School of Los Angeles",
            subtype: "venue",
            state: "CA",
            coords: "34.146167,-118.118334",
            type: "venue"
          }
        }
      ]
    }
  },
  artist: {
    hits: {
      hit: [
        {
          id: "babs",
          fields: {
            name: "Barbra Streisand",
            genres: ["Everything", "The World"]
          }
        },
        {
          id: "emmet",
          fields: {
            name: "Emmet Otter",
            genres: ["Jug Band", "Boggy Creek Boogie"]
          }
        },
        {
          id: "met",
          fields: {
            name: "Metallica",
            genres: ["No", "and No"]
          }
        }
      ]
    }
  }
};

const demo = {
  id: "BviMfV2",
  name: "FALLOWS",
  properties: {
    numbered: true,
    pageoftotaltitle: true,
    env: "development",
    data: {
      fillable: [
        {
          name: "intro_text",
          title: "Intro Text"
        },
        {
          name: "intro_text_es",
          title: "Intro Text Spanish"
        },
        {
          name: "artist_name",
          title: "Artist Name"
        },
        {
          name: "artist_plural",
          title: "Band or Individual",
          default: "our"
        },
        {
          name: "band_name",
          title: "Band Name"
        },
        {
          name: "prize_text",
          title: "Prize Text",
          default: "a prize"
        },
        {
          name: "gender",
          type: "gender",
          title: "Gender"
        },
        {
          name: "background_color",
          type: "color",
          title: "Survey Background Color",
          default: "white"
        },
        {
          name: "prev_button_color",
          type: "color",
          title: "Prev Button Color"
        },
        {
          name: "next_button_color",
          type: "color",
          title: "Next Button Color"
        },
        {
          name: "background_image_url",
          title: "Background Image URL",
          default:
            "http://afromatrix.net/wp-content/uploads/2016/01/music-banner-1.jpg"
        },
        {
          name: "banner_url",
          title: "Banner Image URL",
          default: "/img/demo_background.png"
        },
        {
          name: "gender_pronoun",
          title: "Gender Pronoun",
          default: "our"
        },
        {
          name: "text_color",
          title: "Text Color",
          default: "black"
        },
        {
          name: "us_me",
          default: "us"
        },
        {
          name: "our_my",
          default: "our"
        },
        {
          name: "tab_color_hover"
        },
        {
          name: "checkbox_color"
        },
        {
          name: "we"
        },
        {
          name: "he_she"
        },
        {
          name: "profile_img"
        }
      ],
      progress_bar: false
    }
  },
  groups: {
    JJTlJV0: {
      name: "intro",
      title: "Hey Guys!",
      intro:
        "I'm super stoked to get back on the road in 2020! I worked my ass off this year to get new music finished and released to all or you...now it's time for some fun.  TOUR WAY!  Let me know which cities I absolutely have to come thru on this next tour.  Can't wait to see you guys in person. ONLY US!",
      position: 0,
      status: 1,
      properties: {
        data: null
      },
      custom_css_classes: "",
      sections: ["qCxLhB9"]
    },
    thankyou: {
      name: "thankyou",
      title: "Thank you so much for the support!",
      intro:
        "If you make it out to a show, make sure to come say what up to me! Looking forward to making some crazy memories in your city. ONLY US!!",
      sections: ["spotify", "social"]
    }
  },
  sections: {
    qCxLhB9: {
      title: "",
      intro: "",
      name: "main",
      asset_id: "",
      properties: {
        data: null,
        position: 0,
        section_type: 1,
        columns: 1
      },
      random: 0,
      group: "JJTlJV0",
      status: 1,
      custom_css_classes: "",
      required: 1,
      items: [
        {
          id: "Qbrg9RH",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 1,
          element_type: 6,
          source: "venue",
          title: "Which venue near you should I perform at?",
          tags: "",
          validators: "",
          name: "",
          placeholder: "Search for a venue by city or name...",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: []
        },
        {
          id: "Qbrg9RH_2",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 1,
          element_type: 6,
          source: "artist",
          title:
            "Which local artist do you think I should collaborate with in 2020?",
          tags: "",
          validators: "",
          name: "",
          placeholder: "Search for an artist by name...",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: []
        },
        {
          id: "Qbrg9RH_3",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 1,
          element_type: 2,
          title: "What's your name?",
          tags: "",
          validators: "",
          name: "",
          placeholder: "First and last name preferred...",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: []
        },
        {
          id: "Qbrg9RH_4",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 1,
          element_type: 4,
          title: "Are you:",
          tags: "",
          validators: "",
          name: "",
          placeholder: "Please select a gender...",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: [
            {
              name: "Female",
              type: 1,
              value: "Female"
            },
            {
              name: "Male",
              type: 1,
              value: "Male"
            },
            {
              name: "Non-Binary/Other",
              type: 1,
              value: "Non-Binary/Other"
            },
            {
              name: "Prefer Not to Say",
              type: 1,
              value: "Prefer Not to Say"
            }
          ]
        },
        {
          id: "Qbrg9RH_5",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 1,
          element_type: 9,
          range_start: -100,
          range_end: -5,
          title: "What year were you born?",
          tags: "",
          validators: "",
          name: "",
          placeholder: "Please select a year...",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: []
        },
        {
          id: "Qbrg9RH_6",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 1,
          element_type: 4,
          source: "country",
          title: "In what country do you currently reside?",
          tags: "",
          validators: "country",
          name: "",
          placeholder: "",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: []
        },
        {
          id: "Qbrg9RH_7",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 1,
          element_type: 2,
          source: "country",
          title: "What is your residential ZIP / Postal Code?",
          tags: "",
          validators: ["zip"],
          name: "",
          placeholder: "5-digit postal code...",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: []
        },
        {
          id: "Qbrg9RH_8",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 1,
          element_type: 2,
          source: "country",
          title: "What's your email address?",
          tags: "",
          validators: ["email"],
          name: "",
          placeholder: "john@email.com",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: []
        },
        {
          id: "Qbrg9RH_9",
          status: 1,
          survey_section_id: "qCxLhB9",
          required: 0,
          element_type: 2,
          source: "country",
          title:
            "Get texts from me or the ONLY US crew by giving us your phone number!",
          tags: "",
          validators: ["phone", "country"],
          name: "",
          placeholder: "",
          custom_css_classes: "",
          default_val: "",
          position: 0,
          matrix_head: null,
          matrix_row: null,
          options: [
            {
              type: "popover",
              header: "Why we're asking for your number...",
              label: "What kinds of texts or things will you send me?",
              content:
                "I/we may periodically text you about things such as upcoming releases, concert/tour dates and general information that you may find useful or interesting.  We promise to never sell your number or spam you with messages.  Also, keep in mind that texting rates may apply."
            }
          ]
        }
      ]
    },
    spotify: {
      uri: "https://open.spotify.com/artist/5Iz4416TJcu3U3s8rPzifd"
    }
  },
  assets: {}
};
