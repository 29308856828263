import React from 'react';
import {ThemeProvider} from 'styled-components';
import {LocationProvider} from 'notes';
import {Routing} from 'Routes';
import {BrowserRouter as Router} from 'react-router-dom';
import {SurveyProvider, SurveyConsumer, FirebaseProvider} from 'Providers';
import {Footer, Loader, ScrollToTopPageChange} from 'Components';
import styled from 'styled-components';
import {IntlProvider} from 'react-intl';
import languages from 'intl';
import {Helmet} from 'react-helmet';
//import LogRocket from "logrocket";

//if (process.env.NODE_ENV === "production") {
//  LogRocket.init("fyqxo2/sts-react");
//}

const AppContainer = styled.div`
  background-color: ${props => (props.theme.dark ? '#000000' : '#FAFAFA')};
  position: relative;
  @media only screen and ${props => props.theme.media.large} {
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    margin: 0 auto;
    max-width: 1304px;
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  min-height: calc(100vh - 185px);
  position: relative;
  padding-bottom: 185px;
`;

function App() {
  return (
    <FirebaseProvider>
      <Router>
        <ScrollToTopPageChange />
        <SurveyProvider>
          <SurveyConsumer>
            {context => {
              const {
                state: {locale, collector},
                theme,
              } = context;
              // change to use utility function that has failover
              return (
                <ThemeProvider theme={theme}>
                  <LocationProvider>
                    <IntlProvider locale={locale} messages={languages[locale]}>
                      <AppContainer>
                        <ContentContainer>
                          {collector && collector.artist && (
                            <Helmet>
                              <title>
                                Set the Set: {collector.artist.name}
                              </title>
                              <meta
                                name="description"
                                content={`"Help ${collector.artist.name} select the setlist for upcoming shows!"`}
                              />
                              <meta
                                name="og:description"
                                content={`"Help ${collector.artist.name} select the setlist for upcoming shows!"`}
                              />
                              <meta
                                name="og:image"
                                content={
                                  !!collector.artist.image
                                    ? collector.artist.image
                                    : collector.artist.images[0].url
                                }
                              />
                            </Helmet>
                          )}
                          {context.loading ? <Loader /> : <Routing />}
                        </ContentContainer>
                        {context.loading ? null : <Footer />}
                      </AppContainer>
                      <div id="bottom-portal" />
                    </IntlProvider>
                  </LocationProvider>
                </ThemeProvider>
              );
            }}
          </SurveyConsumer>
        </SurveyProvider>
      </Router>
    </FirebaseProvider>
  );
}

export default App;
